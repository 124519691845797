import React from 'react'
import BmFormField from '../../../BmLibrary/BmComponents/BmForms/BmFormField';
import BmParentCollapsibleLine from '../../../BmLibrary/BmComponents/BmParentContainers/BmParentCollapsibleLine';
import BmSegment from '../../../BmLibrary/BmComponents/BmParentContainers/BmSegment';
import { withTranslation } from 'react-i18next';
import BmForm from '../../../BmLibrary/BmComponents/BmForms/BmForm';
import BmParentSpacer from '../../../BmLibrary/BmComponents/BmParentContainers/BmParentSpacer';
import BmFormDateField from '../../../BmLibrary/BmComponents/BmForms/BmFormDateInputs/BmFormDateField';
import BmFormSubmitButton from '../../../BmLibrary/BmComponents/BmForms/BmFormSubmitButton';
import BmHeader from '../../../BmLibrary/BmComponents/BmHeaders/BmHeader';
import BmFormPhoneField from '../../../BmLibrary/BmComponents/BmForms/BmFormPhoneField';
import OpersTable from '../Operations/OpersTable';

import reportsApi from '../../API/reportsApi';
import { get_node_return_error } from '../../../BmLibrary/BmAxiosApi/BmNodeHandlingFunctions';
import BmModal from '../../../BmLibrary/BmComponents/BmModals/BmModal';
import BmErrorModal from '../../../BmLibrary/BmComponents/BmModals/BmErrorModal';
import BmTable from '../../../BmLibrary/BmComponents/BmTable';
import BmCloseButton from '../../../BmLibrary/BmComponents/BmButtons/BmCloseButton';
// import { getDayString } from '../../../BmLibrary/BmFunctions/BmDateFunctions';
import BmParentAnimator from '../../../BmLibrary/BmComponents/BmParentContainers/BmParentAnimator';
import BmFormBirthdaySelector from '../../../BmLibrary/BmComponents/BmForms/BmFormDateInputs/BmFormBirthdaySelector';
import BmSeparator from '../../../BmLibrary/BmComponents/BmSeparator';
import BmLabel from '../../../BmLibrary/BmComponents/BmLabel';
import BmParentFlexRow from '../../../BmLibrary/BmComponents/BmParentContainers/BmParentFlexRow';
 
class GeneralRep extends React.Component{

initialState = {
    formValues: {
        name: "",
        surname: "",

        birthDate: null,
        nationalID: "",

        phone: "",
        email: "",

        address: "",
        city: "",

        visitDateBegin: null,
        visitDateEnd: null,

        visitText: "",
        t_oper: [],

        birthDay: null,
        birthMonth: null
    },

    posting: false,
    error: "",
    showOperModal: false,

    t_records: []
}

state = {...this.initialState}

onSubmit = async(is_formValues) => {

    const ls_formValues = {...is_formValues};

    console.log('ls_formValues:', ls_formValues);

    ls_formValues.t_oper = this.state.formValues.t_oper;

    this.setState({formValues: ls_formValues});

    this.setState({posting: true,  error: ""})
    
    try {

        let ls_response = await reportsApi.genRep(ls_formValues);

        if (ls_response.data.length > 0)
        {
            this.setState({
                posting: false,
                t_records: ls_response.data
            })
        }
        else
        {
            this.setState({
                posting: false,
                error: this.props.t("BmLabels.noRecFound")
            })            
        }

        
    } catch (error) {

        const lv_error_mess = get_node_return_error(error);
        this.setState({posting: false, error: lv_error_mess})        
    }
}

onSearchRecClick = (ip_fcode, ip_index) => {

    if (ip_fcode === "dispPatient")
    {
        // şu anki state kaydet :
        this.saveCurrentStateToRouter();
    
        // seçilen satırdaki hastaya route et :
        this.navigateToPatient(this.state.t_records[ip_index]._id);

    }
}

saveCurrentStateToRouter = () => {

    const ls_curr_state = {...this.props.history.location, state: {...this.state}};
    this.props.history.replace(ls_curr_state);        

}

navigateToPatient = (id) => {

    // -- bu sayfaya geri dönülünce state kaybolmaması için history'e yaz :                
    this.saveCurrentStateToRouter();

    // -- diğer sayfaya route et :        
    const ls_target_state = { readPatientId: id};
    this.props.history.push({pathname:'/patientEntry', state: ls_target_state});

}    

componentDidMount = () => {

    const {state: routerState} = this.props.history.location;

    if (routerState)
    {

        const ls_old_state = {...this.initialState, ...routerState};
        this.setState(ls_old_state);
    }

}


render() {
return( 

    <>

    <BmParentAnimator>

    <BmParentSpacer>

        <div style={{marginBottom: "10px"}}>

            <BmHeader 
                title={this.props.t("generalLabels.genSearch")} 
                icon="search"
            />   

        </div>           

        <BmForm
            initialValues={this.state.formValues}
            onSubmit={ (values) => this.onSubmit(values) }
            // validationSchema={this.validationSchema}
            // formHeaderText={this.props.t("visits.visitInfo" + ":")}
            loading={this.state.posting}
            // formikRef={this.visitFormRef}
            inSegment={false}
        >

            <BmSegment 
                headerText={this.props.t("patientMaster.patientInfoTitle") + " :"} 
                color="blue"
            >            

                <BmParentCollapsibleLine>

                    <BmFormField
                        fieldname="name"
                        placeholder={this.props.t("generalLabels.name") + ":"}
                    />

                    <BmFormField
                        fieldname="surname"
                        placeholder={this.props.t("generalLabels.surname") + ":"}
                    />                  

                    <BmFormPhoneField
                        fieldname="phone"
                        placeholder={this.props.t("generalLabels.phone") + ":"}       
                    />                    

                    <>
                        <BmFormDateField 
                            fieldname="birthDate"
                            placeholder={this.props.t("generalLabels.birthDate") + ":"}       
                        />
                    </>


                </BmParentCollapsibleLine>


                <BmParentCollapsibleLine>

                    <BmFormField
                        fieldname="nationalID"
                        placeholder={this.props.t("generalLabels.nationalID") + ":"}       
                        icon="male"
                    />                    

                    <BmFormField
                        fieldname="email"
                        placeholder={this.props.t("generalLabels.email") + ":"}       
                        icon="mail"
                    />                  

                    <BmFormField
                        fieldname="address"
                        placeholder={this.props.t("generalLabels.address") + ":"}       
                        icon="home"
                    />                  

                    <BmFormField
                        fieldname="city"
                        placeholder={this.props.t("generalLabels.city") + ":"}       
                        icon="map"
                    />                      

                </BmParentCollapsibleLine>

                <BmSeparator />

                <BmParentFlexRow>

                    <div style={{flex: "2"}}>

                        <BmLabel 
                            text={this.props.t("patientMaster.searchWithBirthDay") + " :"} 
                            suLabel={true}
                        />                    

                    </div>

                    <div style={{flex: "8"}}>
                        <div style={{width: "60%"}}>
                            <BmFormBirthdaySelector />
                        </div>
                    </div>


                </BmParentFlexRow>

            </BmSegment>            

            {/* Ziyaret bilgileri : tarih / işlem : */}

            <BmSegment 
                headerText={this.props.t("visits.visitInfo") + " :"}
            >      

                <>

                    <BmParentCollapsibleLine>

                        <BmFormDateField 
                            fieldname="visitDateBegin"
                            placeholder={this.props.t("BmLabels.startDate") + ":"}       
                        />

                        <BmFormDateField 
                            fieldname="visitDateEnd"
                            placeholder={this.props.t("BmLabels.endDate") + ":"}       
                        />

                    </BmParentCollapsibleLine>
                
                </>

                <BmFormField
                    fieldname="visitText"
                    placeholder={this.props.t("visits.visitText") + ":"}       
                    icon="file text"
                />                 

                <OpersTable 
                    t_oper={this.state.formValues.t_oper}
                    setStateOper={
                        (it_oper) => {

                            let ls_formValues = this.state.formValues;
                            ls_formValues.t_oper = it_oper;
                            this.setState({formValues: ls_formValues})

                        }
                    }
                />

            </BmSegment>                        

            <BmFormSubmitButton asSearchButton={true} />                     
           
        </BmForm>

    </BmParentSpacer>    

    </BmParentAnimator>

    <BmModal 
        visible={this.state.t_records.length > 0}
    >

        <BmHeader 
            title={this.props.t("generalLabels.searchResults") 
                + " (" 
                + this.state.t_records.length.toString() 
                + " " + this.props.t("BmLabels.records") 
                +")"} 
            size="h3" />        

        <BmTable
            it_fcat={[
                {fieldname: "name",             title: this.props.t("generalLabels.name")},
                {fieldname: "surname",          title: this.props.t("generalLabels.surname")},
                {fieldname: "birthDateDay",     title: this.props.t("generalLabels.birthDate")},                
                {fieldname: "visitDateDay",     title: this.props.t("visits.visitDate")},
                {fieldname: "phone",            title: this.props.t("generalLabels.phone")},
                {fieldname: "operText",         title: this.props.t("operations.operText")},

                {fieldname: "button_detail",    
                    title: this.props.t("patientMaster.dispPatient"), 
                    function_code: "dispPatient", 
                    button_icon: "folder open",
                    button_color: "teal"
                }                
            ]}

            it_records={this.state.t_records}

            onLineClick={this.onSearchRecClick}
            maxHeight="60vh"
        />



        <BmCloseButton onClick={() => this.setState({t_records: []}) } />

    </BmModal>

    <BmErrorModal
        visible={this.state.error}
        text={this.state.error}
        onClose={ () => this.setState({error: ""})}
    />

    </>

);
}
}

export default withTranslation()(GeneralRep);
