import React from 'react'
import BmParentAnimator from '../../../BmLibrary/BmComponents/BmParentContainers/BmParentAnimator';
import { withTranslation } from 'react-i18next';

import patientApi from '../../API/patientApi';

import BmModal from "../../../BmLibrary/BmComponents/BmModals/BmModal";
import PatientForm from "./PatientForm";
import BmTable from "../../../BmLibrary/BmComponents/BmTable";
import BmButton from '../../../BmLibrary/BmComponents/BmButtons/BmButton';
import BmCloseButton from '../../../BmLibrary/BmComponents/BmButtons/BmCloseButton';
import BmHeader from '../../../BmLibrary/BmComponents/BmHeaders/BmHeader';
import BmMessage from '../../../BmLibrary/BmComponents/BmMessages/BmMessage';
import BmParentSpacer from '../../../BmLibrary/BmComponents/BmParentContainers/BmParentSpacer';
import { get_node_return_error } from '../../../BmLibrary/BmAxiosApi/BmNodeHandlingFunctions';
import BmDeleteButton from '../../../BmLibrary/BmComponents/BmButtons/BmDeleteButton';

import { getDayString } from '../../../BmLibrary/BmFunctions/BmDateFunctions';
import BmConfirm from '../../../BmLibrary/BmComponents/BmModals/BmConfirm';
import BmSegment from '../../../BmLibrary/BmComponents/BmParentContainers/BmSegment';
import BmCreateButton from '../../../BmLibrary/BmComponents/BmButtons/BmCreateButton';
import BmErrorModal from '../../../BmLibrary/BmComponents/BmModals/BmErrorModal';


// import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';


class PatientEntryPage extends React.Component{

    initialState =  
    {
        posting: false,
        error: "",
        infoMessage: "",
        // phone: "",
        patient : 
        {
            _id: "",
            name: '', 
            surname: '', 
            birthDate: null,
            nationalID: '',
            phone: "",
            email: '',
            address: "",
            city: "",
            showDeleteConfirm: false,
            t_visits: []
   
        //    name: 'ahmet', 
        //    surname: 'atar', 
        //    birthDate: null,
        //    nationalID: '122222',
        //    phone: "",
        //    email: 'aa@bb.com',
        //    address: "mahalle sokak sehir",
        //    city: "istanbul",
        //    readPatientId: "",
        //    showDeleteConfirm: false,
       },
   
        existingRecord : {},
        searchRecords: [],

        readPatientId: "",

        searchTitle: this.props.t("generalLabels.searchResults")
    }

    state = {...this.initialState}

    componentDidMount = () => {

        const {state: routerState} = this.props.history.location;

        if (routerState)
        {
            // router'da setli bir state var ise 
            // başka bir sayfadan state ile ..history.push
            // ya da goBack ile gelinmiştir :

            this.routerStateOper(routerState);            

        }
    }

    componentDidUpdate = (prevProps) => {

        // bu komponente kendisinden route edilmektedir 
        // (yeni kayıt yaratılırken, benzer kayıtlar öneri/görüntüleme özelliği) :

        if (prevProps.location.key === this.props.location.key)
        {
            // aynı route adımında state update oldu ise (bir şey yapılmaz)
        }
        else
        {
            // aynı komponente farklı route adımı ile gelindi ise 
            // history state'de "readPatientId" alanı setli ise bu id'deki hastayı db'den okur ve gösterir 
            // ya da goBack ile gelinmiştir eski state'e döner :

            const {state: routerState} = this.props.history.location;
            this.routerStateOper(routerState);

        }
    }    

    routerStateOper = (routerState) => {

        if (routerState)
        {
            this.setState(this.initialState);
    
            if (routerState.readPatientId)
            {
                // bir ID'deki hastayı görüntülemek icin
                // history.push('/page', state: {id: readPatientId })
                // şeklinde gelinmiştir :
    
                this.readPatient(routerState.readPatientId);
            }
            else
            {
                // readPatientId yok ise , mesela sadece goBack ile geri gelinmiş olabilir
                // history'deki state ne ise onu almalıdır :
    
                const ls_old_state = {...this.initialState, ...routerState};
                this.setState(ls_old_state);
            }
        }
    }

    readPatient = async(id) => {

        // this.setState(this.initialState);

        const { state: routerState } = this.props.history.location;

        this.setState({posting: true});

        try {

            const ls_response = await patientApi.readPatientById(id);
            const ls_state = {...this.initialState};

            ls_state.patient        = ls_response.data;

            for (const ls_visit of ls_state.patient.t_visits) {
                ls_visit.visitDateDay = getDayString(ls_visit.visitDate);
                ls_visit.visitText = ls_visit.visitText.slice(0,50) + "...";
            }

            ls_state.readPatientId  = routerState.readPatientId

            this.setState(ls_state);              
                
        } catch (error) {

            const lv_message = get_node_return_error(error, this.props.i18n);                        

            this.setState({ 
                posting: false,
                error: lv_message
            });              
        }
    }    

    onSearchRecClick = (ip_fcode, ip_index) => {

        const ls_searchRec = this.state.searchRecords[ip_index];

        if (ls_searchRec)
        {
            this.navigateToOtherPatient(ls_searchRec._id)            
        }
    }

    onVisitRecClick = (ip_fcode, ip_index) => {    

        if (ip_fcode === "dispVisit" )
        {
            const ls_visitRec = this.state.patient.t_visits[ip_index];
    
            if (ls_visitRec){
                // navigate to visit page :

                this.navigateToVisitPage(ls_visitRec._id);
            }
        }

    }

    saveCurrentStateToRouter = () => {
        // -- bu sayfaya geri dönülünce state kaybolmaması için history'e yaz :        

        const ls_state = {...this.state};

        // başka bir sayfaya gidilip dönülünce, 
        // error, info vs mesajları yok et :

        ls_state.infoMessage = "";
        ls_state.error = "";

        // ziyaret gibi diğer sayfalara gidilip geri dönülünce
        // hasta bilgisinin tekrar okunması için readPatientId alanını doldur :

        if (ls_state.patient._id)
            ls_state.readPatientId = ls_state.patient._id;

        const ls_curr_state = {...this.props.history.location, state: ls_state};
        this.props.history.replace(ls_curr_state);        
    }

    navigateToOtherPatient = (id) => {

        // -- bu sayfaya geri dönülünce state kaybolmaması için history'e yaz :                
        this.saveCurrentStateToRouter();

        // -- diğer sayfaya route et :        
        const ls_target_state = {...this.initialState, readPatientId: id};
        this.props.history.push({pathname:'/patientEntry', state: ls_target_state});

    }    

    navigateToVisitPage = (visitId=null) => {

        // -- bu sayfaya geri dönülünce state kaybolmaması için history'e yaz :                
        this.saveCurrentStateToRouter();

        // -- ziyaret girişi sayfasına route et :        
        const ls_target_state = {patientInfo: {...this.state.patient}};

        if (visitId)
            ls_target_state.visitId = visitId;

        this.props.history.push({pathname:'/visitEntry', state: ls_target_state});        

    }

    deletePatient = async() => {

        this.setState({posting: true, error: "", infoMessage: "", showDeleteConfirm: false});        

        try {

            await patientApi.deletePatient(this.state.patient._id);       

            const ls_init_state = {...this.initialState};

            ls_init_state.infoMessage = this.props.t("patientMaster.patientDeleted")

            this.setState(ls_init_state);             
            
        } catch (error) {

            const lv_message = get_node_return_error(error, this.props.i18n);                                    

            this.setState({posting: false, error: lv_message});                            
        }        
    }

    onSubmitSave = async(data) => {

        // zaten mevcut bir kayıt mıdır , yeni midir ?

        let ls_record = {...data};        
        ls_record.t_visits = this.state.patient.t_visits;

        if (this.state.patient._id) // mevcut kayıt
        {
            ls_record._id = this.state.patient._id;
        }

        this.setState({ patient: ls_record});  // <<-- formik + id (varsa) datasını state'e koy!

        this.setState({posting: true, error: "", infoMessage: ""});

        try {

            const ls_response = await patientApi.savePatient(ls_record);

            // dönüş mesajı yaratıldı / güncellendi :
            let lv_message = this.props.t("patientMaster.patientCreated") // hasta yaratıldı
            if (ls_record._id)
            {
                lv_message = this.props.t("patientMaster.patientUpdated") // hasta güncellendi
            }

            // dönen hasta kaydına t_visits , ziyaretler array bilgisi ekle :
            let ls_patient_returned = {...ls_response.data};
            ls_patient_returned.t_visits = [];

            this.setState({
                posting: false,
                error: "",
                patient:ls_patient_returned,
                infoMessage:  lv_message
            });
            
        } catch (error) {

            if (error.response.data.stat)
            {
                if (error.response.data.stat === 'nationId_already_exists')
                {
                    // aynı kimlik no'da kayıtlı hasta zaten var modal ile gosterilir :
                    this.setState({existingRecord: error.response.data.record})
                }
                else if (error.response.data.stat === 'name_birthdate_exists')
                {
                    // aynı isim ve doğum tarihinde hastalar mevcuttur , önerilmeli
                    const lt_searchRecords = error.response.data.records;

                    for (const ls_searchRec of lt_searchRecords) {
                        ls_searchRec.birthDateDay = getDayString(ls_searchRec.birthDate);
                    }

                    this.setState({searchRecords: lt_searchRecords, searchTitle: this.props.t("patientMaster.similarPatExists")});                    
                }

            }
            else
            {

                const lv_error_mess = get_node_return_error(error);

                this.setState({error: lv_error_mess})
            }            

            this.setState({posting: false})            
        }


    }

    onSubmitSearch = async(data) => {

        let ls_record = {...data};

        ls_record.t_visits = this.state.patient.t_visits;

        this.setState({ patient: ls_record});  // <<-- formik + id (varsa) datasını state'e koy!
    
        this.setState({posting: true, error: ""});
    
        try {
            
            const ls_response = await patientApi.searchPatient(ls_record);      

            const lt_searchRecords = ls_response.data;

            if (lt_searchRecords.length > 0)
            {
                for (const ls_searchRec of lt_searchRecords) {
                    ls_searchRec.birthDateDay = getDayString(ls_searchRec.birthDate);
                }

                this.setState({posting: false, 
                               searchRecords: lt_searchRecords, 
                               searchTitle: this.props.t("generalLabels.searchResults")}
                               );
            }
            else
                this.setState({posting: false, error: this.props.t("patientMaster.noRecFound")});

            
        } catch (error) {

            const lv_error_mess = get_node_return_error(error);
    
            this.setState({posting: false, error: lv_error_mess})        
            
        }
    }

    onSubmit = async(data) => {

        if (this.props.asSearchForm)
        {
            await this.onSubmitSearch(data);
        }
        else
        {
            await this.onSubmitSave(data);
        }

    }

    render() {

        // sayfa başlığı :
        let lv_pageTitle = "";
        let lv_titleIcon = "";
        if (this.props.asSearchForm) 
        {
            lv_pageTitle = this.props.t("patientMaster.patientSearchTitle")
            lv_titleIcon = "search";
        }
        else                        
        {
            lv_pageTitle = this.props.t("patientMaster.newPatientTitle");
            lv_titleIcon = "sticky note outline";
        }
        
    
    return( 
        
    <>

        <BmParentAnimator>

        <>  

            <BmParentSpacer>

                {this.state.readPatientId ? 

                    <>
                        <BmButton 
                            text={this.props.t("generalLabels.goBack")} 
                            onClick={ () => this.props.history.goBack()} 
                            icon="arrow left"
                            color="black"
                        />

                        <BmHeader 
                            title={this.props.t("patientMaster.patientInfoTitle")} 
                            icon="address book"
                        />                        

                    </>
                    :
                    <BmHeader 
                        title={lv_pageTitle} 
                        icon={lv_titleIcon}
                    />                                            

                }

                <BmMessage visible={this.state.infoMessage} message={this.state.infoMessage} 
                           success={true}/>

                <BmErrorModal 
                    visible={this.state.error} 
                    text={this.state.error}
                    onClose={ () => this.setState({error: ""})}
                />       

                <PatientForm 
                    edit={true} 

                    formValues={this.state.patient}

                    onSubmit={(values) => this.onSubmit(values)}
                    posting={this.state.posting}

                    padding={false}
                    asSearchForm={this.props.asSearchForm}

                    headerText={this.props.t("patientMaster.patientInfoTitle") + " :"}

                />

                {this.state.patient._id &&

                    <>

                        {/* Geçmiş ziyaretler : */}

                        <BmSegment 
                            maxHeight={400}
                            headerText={this.props.t("visits.visitInfo") + " :"} 
                        >

                            <BmButton 
                                text={this.props.t("visits.visitEntyTitle")} 
                                color="teal" 
                                icon="add"
                                onClick={ () => this.navigateToVisitPage() }
                                padding="10px"
                            />


                            {this.state.patient.t_visits.length > 0 &&

                                <>

                                <BmTable
                                    it_records={this.state.patient.t_visits}                                
                                    it_fcat={[
                                        {fieldname: "visitDateDay", title: this.props.t("generalLabels.date")},
                                        {fieldname: "visitText",    title: this.props.t("visits.visitInfo")},
                                        {fieldname: "paidAmount",   title: this.props.t("visits.paidAmount")},
                                        {fieldname: "remAmount",    title: this.props.t("visits.remAmount")},
                                        {fieldname: "button_detail",    
                                            title: this.props.t("generalLabels.disp_detail"), 
                                            function_code: "dispVisit", 
                                            button_icon: "folder open",
                                            button_color: "teal"
                                        }                
                                    ]}

                                    onLineClick={this.onVisitRecClick}
                                />

                                </>
                            }
                        </BmSegment>    

                        <BmDeleteButton 
                            text={this.props.t("patientMaster.deletePatient")} 
                            onClick={() => this.setState({showDeleteConfirm: true}) }
                        />

                    </>
                }

            </BmParentSpacer>

        </>

        </BmParentAnimator>

        <BmModal 
            visible={Object.keys(this.state.existingRecord).length !== 0}
            // headerText={this.props.t("patientMaster.natIdExist")}
        >

            <BmHeader 
                title={this.props.t("patientMaster.natIdExist")}
                icon="warning circle"
            />

            <PatientForm 
                edit={false} 
                formValues={this.state.existingRecord}
            />  

            <BmButton 
                text={this.props.t("patientMaster.dispPatient")}     
                onClick={ () => this.navigateToOtherPatient(this.state.existingRecord._id)}
                icon="folder open"
            />

            <BmCloseButton onClick={() => this.setState({existingRecord:{}})} />

        </BmModal>

        <BmModal 
            visible={this.state.searchRecords.length > 0}
        >

            <BmHeader title={this.state.searchTitle} size="h3" />
                
            <BmTable
                it_fcat={[
                    {fieldname: "name",             title: this.props.t("generalLabels.name")},
                    {fieldname: "surname",          title: this.props.t("generalLabels.surname")},
                    {fieldname: "birthDateDay",     title: this.props.t("generalLabels.birthDate")},
                    {fieldname: "nationalID",       title: this.props.t("generalLabels.nationalID")},
                    {fieldname: "button_detail",    
                     title: this.props.t("generalLabels.disp_detail"), 
                     function_code: "dispDetail", 
                     button_icon: "folder open",
                     button_color: "teal"
                    }                
                ]}

                it_records={this.state.searchRecords}

                onLineClick={this.onSearchRecClick}
            />

            {/* Arama ekranı değil , hasta girilirken benzer hasta bulundu ise createNewRecord butonu görünür : */}
            {!this.props.asSearchForm &&

                <BmCreateButton 
                    onClick={
                        () =>
                        {
                                                    
                            const ls_patient = this.state.patient;
                            ls_patient.forceNewEntry = true; 

                            this.setState({searchRecords: []});                        

                            this.onSubmit(this.state.patient) 
                        }
                    }

                />

            }

            <BmCloseButton onClick={() => this.setState({searchRecords: []}) } />

        </BmModal>

        <BmConfirm 
            visible={this.state.showDeleteConfirm}
            text={this.props.t("patientMaster.confirmDeletePatient")}
            onCancel={ () => this.setState({showDeleteConfirm: false})}
            onConfirm= { () => this.deletePatient() }
        />

    </>

)
}
}
    
export default withTranslation()(PatientEntryPage);