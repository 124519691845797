import React from 'react'
import BmModal from '../../../BmLibrary/BmComponents/BmModals/BmModal';
import { withTranslation } from 'react-i18next';
import operApi from '../../API/operApi';
import { get_node_return_error } from '../../../BmLibrary/BmAxiosApi/BmNodeHandlingFunctions';
import BmCancelButton from '../../../BmLibrary/BmComponents/BmButtons/BmCancelButton';
import BmLoadingMessage from '../../../BmLibrary/BmComponents/BmMessages/BmLoadingMessage';
import BmErrorMessage from '../../../BmLibrary/BmComponents/BmMessages/BmErrorMessage';
import BmItemSelectionModal from '../../../BmLibrary/BmComponents/BmModals/BmItemSelectionModal';
 
class OperSelModal extends React.Component{

    state = {
        t_operOptions: [],
        posting: false,
        error: "",
        infoMessage: ""
    
    }

    componentDidMount = async() => {

        if (this.state.t_operOptions.length > 0)
        {
            // işlemler daha önce okunmuştur, zaten state tedir, popup gösterilir :    
        }
        else
        {

            this.setState({
                posting: true,
                error: "",
                infoMessage: this.props.t("operations.readingOperOptions")
            });
                        
            try {

                const ls_response_oper = await operApi.getOpers();

                this.setState({
                    posting: false,
                    infoMessage: ""
                });            

                const lt_opers = ls_response_oper.data;

                if (lt_opers.length > 0)
                {
                    this.setState({

                        t_operOptions: lt_opers

                    })
                }
                else
                {

                    // veritabanında henüz oper tanımları girilmemiş                     
                    this.setState({
                        error: this.props.t("operations.noOperCustomized"),
                    })                    

                }
                
            } catch (error) {

                const lv_error_mess = get_node_return_error(error);
                this.setState({posting: false, error: lv_error_mess, infoMessage: ""});
                return;                    
                
            }
        }

    }

render() {

    if (this.state.posting)
    {
        return (
            <BmModal visible={this.props.visible}>

                <BmLoadingMessage title={this.props.t("operations.readingOperOptions")} />
                <BmCancelButton onClick={() => this.props.onCancel()} />

            </BmModal>
        )
    }

    else if (this.state.error)
    {

        return (
            <BmModal visible={this.props.visible}>

                <BmErrorMessage message={this.state.error} />
                <BmCancelButton onClick={() => this.props.onCancel()} />

            </BmModal>
        )        
    }

return( 

    <BmItemSelectionModal 
        visible={this.props.visible}
        t_items={this.state.t_operOptions}
        columnTextField="operText"
        onClick={ 
            // (item) => console.log('item on click', item)
            
            (item) => this.props.onClickItem(item)
        }
        onCancel={ () => this.props.onCancel() }
        headerText={this.props.t("operations.selectOper")}
    />    

);
}
}

export default withTranslation()(OperSelModal);


