import React from 'react';
import { withTranslation } from 'react-i18next';
import BmButton from '../../../BmLibrary/BmComponents/BmButtons/BmButton';
import BmHeader from '../../../BmLibrary/BmComponents/BmHeaders/BmHeader';
import BmCollapsiblePane from '../../../BmLibrary/BmComponents/BmParentContainers/BmCollapsiblePane';
import BmParentAnimator from '../../../BmLibrary/BmComponents/BmParentContainers/BmParentAnimator'
import BmParentSpacer from '../../../BmLibrary/BmComponents/BmParentContainers/BmParentSpacer'
import BmSegment from '../../../BmLibrary/BmComponents/BmParentContainers/BmSegment';
import BmSeparator from '../../../BmLibrary/BmComponents/BmSeparator';
import PatientForm from '../Patients/PatientForm';
import visitApi from '../../API/visitApi';
import { get_node_return_error } from '../../../BmLibrary/BmAxiosApi/BmNodeHandlingFunctions';
import BmMessage from '../../../BmLibrary/BmComponents/BmMessages/BmMessage';
import BmDeleteButton from '../../../BmLibrary/BmComponents/BmButtons/BmDeleteButton';
import BmConfirm from '../../../BmLibrary/BmComponents/BmModals/BmConfirm';
import BmSaveButton from '../../../BmLibrary/BmComponents/BmButtons/BmSaveButton';
import operApi from '../../API/operApi';
import BmItemSelectionModal from '../../../BmLibrary/BmComponents/BmModals/BmItemSelectionModal';
import BmTable from '../../../BmLibrary/BmComponents/BmTable';
import BmErrorModal from '../../../BmLibrary/BmComponents/BmModals/BmErrorModal';
import BmForm from '../../../BmLibrary/BmComponents/BmForms/BmForm';
import * as Yup from "yup";
import BmFormDateField from '../../../BmLibrary/BmComponents/BmForms/BmFormDateInputs/BmFormDateField';
import BmFormLongDescField from '../../../BmLibrary/BmComponents/BmForms/BmFormLongDescField';
import BmParentCollapsibleLine from '../../../BmLibrary/BmComponents/BmParentContainers/BmParentCollapsibleLine';
import BmFormField from '../../../BmLibrary/BmComponents/BmForms/BmFormField';
import OperSelModal from '../Operations/OperSelModal';
import OpersTable from '../Operations/OpersTable';
 
class VisitEntryPage extends React.Component{

    initialVisitData = 
    {
        _id: "",
        visitDate: new Date(),
        visitText: "",
        paidAmount: 0,
        remAmount: 0,

        t_oper: []
     }


    initialState =  
    {
        posting: false,
        error: "",
        infoMessage: "",

        visit: {...this.initialVisitData},

        patientInfo :null,

        showDeleteConfirm: false,
        showOperModal: false

        // patientInfo :{
        //     _id:  "",
        //     name: 'Yüksel', 
        //     surname: 'Erişen', 
        //     birthDate: null,
        //     nationalID: '1122',
        //     phone: "",
        //     email: 'ye@gmail.com',
        //     address: "",
        //     city: "",
        //     showDeleteConfirm: false            

        // },
        

    }

    state = {...this.initialState}

    validationSchema = Yup.object().shape({
        
        visitDate: Yup.date().required(this.props.t("visits.enterDate")),
        // visitText: Yup.string().required(t(),
        paidAmount: Yup.number().typeError(this.props.t("generalLabels.enterNumeric")),
        remAmount: Yup.number().typeError(this.props.t("generalLabels.enterNumeric"))

    })

    constructor(props) {
        super(props);
        this.visitFormRef = React.createRef();
    }    

    triggerFormSubmit = () => {
        
        if (this.visitFormRef.current) 
            this.visitFormRef.current.handleSubmit();
    }

    onSubmit = async(data) => {

        console.log('onSubmit triggered .. values:', data);        

        // state üzerinde visit yapısının formdan gelecek alanlar ile güncellenmesi :

        let ls_record = {...data};        
        if (this.state.visit._id) // mevcut kayıt
        {
            ls_record._id     = this.state.visit._id;
            ls_record.t_oper  = this.state.visit.t_oper;
        }

        this.setState({ visit: ls_record});  // <<-- formik + id (varsa) datasını state'e koy!                

        // db kayıt için patiendID eklenmesi :
        ls_record.patientId = this.state.patientInfo._id;

        console.log('ls_record to save:', ls_record);

        this.setState({posting: true, error: "", infoMessage: ""});

        try {

            const ls_response = await visitApi.saveVisit(ls_record);

            // dönüş mesajı yaratıldı / güncellendi :
            let lv_message = this.props.t("visits.visitCreated") // ziyaret yaratıldı
            if (ls_record._id)
            {
                lv_message = this.props.t("visits.visitUpdated") //ziyaret güncellendi
            }

            this.setState({
                posting: false,
                error: "",
                visit:ls_response.data,
                infoMessage:  lv_message
            });
            
        } catch (error) {

            const lv_error_mess = get_node_return_error(error);
            this.setState({error: lv_error_mess, posting: false})

        }

    }

    componentDidMount = async() => {

        // router state :

        const {state: routerState} = this.props.history.location;

        if (routerState)
        {

            // hasta ID verilmiş ama ziyaret no yok ise
            // o hasta icin yeni ziyaret yaratılacaktır :
            if (routerState.patientInfo)
            {

                if (!routerState.visitId)
                    this.setState({patientInfo: routerState.patientInfo, visit: this.initialVisitData})
                else
                {

                    //router state'den gelen hasta ve ziyaret ID'si state'e eklnsin :
                    let ls_visit  = {...this.initialVisitData};
                    ls_visit._id = routerState.visitId;

                    this.setState({patientInfo: routerState.patientInfo, 
                                  visit: ls_visit})                

                    // ziyaret bilgisini db'den oku : 
                    this.setState({posting: true, error: "", infoMessage: ""});

                    try {

                        const ls_response = await visitApi.readVisitById(routerState.visitId);

                        let ls_visit = {...ls_response.data};

                        console.log('lv_visit_read:', ls_visit);

                        this.setState({posting: false, visit: ls_visit});

                    } catch (error) {

                        const lv_error_mess = get_node_return_error(error);

                        this.setState({posting: false, error: lv_error_mess, infoMessage: ""});                    
                        
                    }
                }
            }
        }
    }

    deleteVisit = async() => {

        this.setState({posting: true , error: "", infoMessage: "", showDeleteConfirm: false});

        try {

            await visitApi.deleteVisit(this.state.visit._id);
            this.props.history.goBack();
            
        } catch (error) {

            const lv_error_mess = get_node_return_error(error);
            this.setState({posting: false, error: lv_error_mess});
            
        }
    }

render() {

    let lo_content = null;

    if (this.state.patientInfo === null)
    {
        // bir hasta ekranından navigate ile gelinMEmiştir
        // ve var olan bir ziyaret kaydı üzerinde de çalışılMIyordur
        // ekrana sadece Hasta arama ve Yeni hasta butonları konur :

        lo_content = 
        <>

            <BmHeader 
                title={this.props.t("visits.visitEntyTitle")} 
                icon="calendar alternate outline"
            />              

            <div style={{paddingTop: "5vh"}}>

                <BmButton 
                    text={this.props.t("patientMaster.patientSearchTitle")}
                    icon="search"
                    size="big"
                    color="teal"
                    onClick={ () => 
                        this.props.history.push({pathname:'/genSearch'})
                    }
                />
                <BmButton 
                    text={this.props.t("patientMaster.newPatientTitle")}
                    icon="add user"
                    size="big"
                    onClick={ () => 
                        this.props.history.push({pathname:'/patientEntry'})
                    }                        
                />

            </div>

        </>

    }
    else
    {

        // Bir hasta sayfasından navigate edilmiştir
        // yeni ziyaret kaydı mı yaratılıyor ? :

        let lv_title = "";

        if (this.state.visit._id === "")
            lv_title = this.props.t("visits.visitEntyTitle")
        else
            lv_title = this.props.t("visits.visitUpdateTitle");

        lo_content = 
        <>

            <BmButton 
                text={this.props.t("generalLabels.goBack")} 
                onClick={ () => this.props.history.goBack()} 
                icon="arrow left"
                color="black"
            />                    

            <BmHeader 
                title={lv_title} 
                icon="calendar alternate outline"
            />      

            {/* üstte hasta bilgileri bloğu : */}

            <BmCollapsiblePane 
                title={this.props.t("patientMaster.patientInfoTitle") 
                        + " : " + this.state.patientInfo.name
                        + " " + this.state.patientInfo.surname
                    }   
            >

                <BmParentAnimator>
                <BmParentSpacer>
                    <BmSegment>
                    
                        <BmHeader 
                            title={this.props.t("patientMaster.patientInfoTitle") + ":"}
                            icon="male"
                            size= "h3"
                            inBlock={false}
                            padding="0px"
                            disabled
                        />

                        <PatientForm
                            edit={false} 
                            formValues={this.state.patientInfo}
                            inSegment={false}
                        />
                    
                    </BmSegment>
                </BmParentSpacer>
                </BmParentAnimator>

            </BmCollapsiblePane>

            <BmSeparator />

            <BmMessage visible={this.state.infoMessage} message={this.state.infoMessage} 
                           success={true}/>

            <BmErrorModal 
                visible={this.state.error} 
                text={this.state.error}
                onClose={ () => this.setState({error: ""})}
            />       


            {/* Ziyaret Bilgileri Giriş Formu : */}

            <BmSegment color="blue">

                <BmForm
                    initialValues={this.state.visit}
                    onSubmit={ (values) => this.onSubmit(values) }
                    validationSchema={this.validationSchema}
                    formHeaderText={this.props.t("visits.visitInfo" + ":")}
                    loading={this.state.posting}
                    formikRef={this.visitFormRef}
                    inSegment={false}
                >

                    {/* Ziyaret Tarihi : */}
                    <BmFormDateField 
                        fieldname="visitDate"
                        placeholder={this.props.t("visits.visitDate") + ":"}       
                    />

                    {/* Ziyaret uzun metni : */}
                    <BmFormLongDescField
                        fieldname="visitText"
                        placeholder={this.props.t("visits.patientInfo") + ":"}       
                    />

                    {/* İşlemler Kısmı : */}

                    <OpersTable 
                        t_oper={this.state.visit.t_oper}
                        setStateOper={
                            (it_oper) => {

                                let ls_visit = {...this.state.visit};
                                ls_visit.t_oper = it_oper;
                                this.setState({visit: ls_visit})
                            }
                        }
                    />

                    {/* Yapılan ödeme / kalan : */}

                    <div style={{paddingTop: "10px", paddingBottom: "10px"}}>

                    <BmParentCollapsibleLine>

                        <BmFormField
                            fieldname="paidAmount"
                            placeholder={this.props.t("visits.paidAmount") + ":"}       
                            icon="credit card" 
                        />                 

                        <BmFormField
                            fieldname="remAmount"
                            placeholder={this.props.t("visits.remAmount") + ":"}       
                            icon="credit card"     
                        />                 

                    </BmParentCollapsibleLine>                    

                    </div>


                </BmForm>


                {/* Kaydet / Sil Butonları : */}
                
                <BmSaveButton onClick={() => this.triggerFormSubmit()} />

                {this.state.visit._id &&                    

                    <BmDeleteButton 
                    text={this.props.t("visits.deleteVisit")}
                    onClick={ () => this.setState({showDeleteConfirm: true}) }
                    />
                
                }



            </BmSegment>

        </>

    }

return( 
    <>
    <BmParentAnimator>
    <>  
    <BmParentSpacer>

        {lo_content}

    </BmParentSpacer>
    </>
    </BmParentAnimator>

    <BmConfirm 
        visible={this.state.showDeleteConfirm}
        text={this.props.t("visits.deleteVisitConfirm")}
        onCancel={ () => this.setState({showDeleteConfirm: false})}
        onConfirm= { () => this.deleteVisit() }
    />

    <OperSelModal 
        visible={this.state.showOperModal}
        onClickItem={ (item) => this.onOperSelect(item)}
        onCancel={ () => this.setState({showOperModal: false})}
    />    

    </>        

);
}
}

export default withTranslation()(VisitEntryPage);