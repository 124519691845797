import React from 'react'
import * as Yup from "yup";
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import BmForm from '../BmComponents/BmForms/BmForm';
import BmFormField from '../BmComponents/BmForms/BmFormField';
import BmFormPasswordField from '../BmComponents/BmForms/BmFormPasswordField';
import BmFlexCenteredColumn from '../BmComponents/BmParentContainers/BmFlexCenteredColumn';
import BmFormSubmitButton from '../BmComponents/BmForms/BmFormSubmitButton';
import BmAppLogo from '../BmDashboard/BmAppLogo';
import BmParentAnimator from '../BmComponents/BmParentContainers/BmParentAnimator';
import { Redux_Set_User } from './BmUserLoginReduxThunk';
import { get_node_return_error } from '../BmAxiosApi/BmNodeHandlingFunctions';

import BmErrorMessage from '../BmComponents/BmMessages/BmErrorMessage';

import bmUserApi from './BmUserApi';;
 
class BmLogonPage extends React.Component{
state = {
    posting: false,
    error: "",

    formValues: {
        email: "",
        password: ""
    }

}

validationSchema = Yup.object().shape({
        
    email: Yup.string()
            .email(this.props.t("BmLabels.enterValidEmail"))
            .required(this.props.t("BmLabels.enterEmail")),

    password: Yup.string().required(this.props.t("BmLabels.enterPassword"))

})

onLogin = async(formValues) => {

    this.setState({loading:true, error: ""});    

    try {

        var ls_response = await bmUserApi.login(formValues);

        this.setState({loading: false});

        const lv_token = ls_response.headers['x-auth-token'];
        Redux_Set_User(lv_token, this.props.redux_dispatch, this.props.history);   

        this.props.history.push('/Home');

    } catch (error) {
        
        const lv_message = get_node_return_error(error, this.props.i18next);            
        this.setState({loading: false,  error: lv_message});            

    }


    // ------------------------------------------------

    // const config = {
    //     headers: {
    //         'Content-Type': 'application/json'
    //     }
    // }

    // let data = JSON.stringify( {
    //     email : formValues.email,
    //     password : formValues.password
    // });

    // this.setState({loading:true, error: ""});

    // Axios.post("/api/users/login", data, config)
    // .then(
    //     response => {

    //         this.setState({loading: false});

    //         const lv_token = response.headers['x-auth-token'];

    //         Redux_Set_User(lv_token, this.props.redux_dispatch, this.props.history);   

    //       }
    // )
    // .catch(

    //     error => {

    //         const lv_message = get_node_return_error(error, this.props.i18next);            

    //         this.setState({loading: false,  error: lv_message});            
    //     }
    // )
}


render() {
return( 
    <>

    <BmParentAnimator>

    <BmFlexCenteredColumn divHeight="90vh">

        <div style={{width: "50vw"}}>

        <BmAppLogo />

        <BmForm
            initialValues={this.state.formValues}
            onSubmit={(values) => this.onLogin(values)}    
            validationSchema={this.validationSchema}
            formHeaderText={this.props.t("BmLabels.logonTitle")}
            loading={this.posting}
        >

            <BmErrorMessage visible={this.state.error} message={this.state.error} />

            <BmFormField
                fieldname="email"
                placeholder={this.props.t("BmLabels.email") + ":"}       
                icon="mail"
            />     

            <BmFormPasswordField fieldname="password" />

            <BmFormSubmitButton 
                text={this.props.t("BmLabels.logon")}
                icon="lock"
            />

        </BmForm>

        </div>
    
    </BmFlexCenteredColumn>

    </BmParentAnimator>
    </>

);
}
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
    };
    
    
const mapDispatchToProps = (dispatch) => {
return {
    redux_dispatch : (ip_action) => dispatch(ip_action)
        };
};
    
export default 
    withRouter(
    connect( mapStateToProps, mapDispatchToProps )(withTranslation()(BmLogonPage))
    );


