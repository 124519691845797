import React from 'react';

function BmParentFlexRow({justifyContent="flex-start", children}){
return (

    <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: justifyContent,
        alignItems: "center"
    }}>

        {children}

    </div>

)
}
export default BmParentFlexRow;
