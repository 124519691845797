import axios from 'axios';
import { convertStringDates } from '../BmFunctions/BmDateFunctions';

const createBmAxiosClient = (is_settings) => {

    const axiosClient = axios.create({

        // baseURL: 'http://192.168.0.28:5000/api',
        // baseURL: 'http://www.kliniksis.com/api',    
        // baseURL:  is_settings.apiUrl,

        timeout: is_settings.timeout

    });    

    axiosClient.interceptors.request.use( 
        async function(config) {
    
            config.headers = {
                'Content-Type': 'application/json'
            }
    
            // token :
            var ls_user = JSON.parse(localStorage.getItem('user'));        

            if (ls_user)
            {
                if (ls_user.token) 
                    config.headers['x-auth-token'] = ls_user.token;
            }
            
            return config;
        },
    
        error => { Promise.reject(error) }
    )
    
    axiosClient.interceptors.response.use( 
    
        response => {
    
            // response.data üzerinde geçen ISO Date olan
            // stringlerin Date objesine çevrilmesi :
    
            convertStringDates(response.data);
            return response;
    
        },
    
        error => {
    
            // error.response.data üzerinde geçen ISO Date olan
            // stringlerin Date objesine çevrilmesi :
    
            if (error.response.data) convertStringDates(error.response.data);        
            return Promise.reject(error);
    
        }
    )    

    return axiosClient;
}

export default createBmAxiosClient;

// const bmAxiosClient = axios.create({

//     // baseURL: 'http://192.168.0.28:5000/api',
//     // baseURL: 'http://www.kliniksis.com/api',    
//     // baseURL:  getSettings().apiUrl,

//     timeout: getSettings().timeout

// });    

// bmAxiosClient.interceptors.request.use( 
//     async function(config) {

//         config.headers = {
//             'Content-Type': 'application/json'
//         }

//         // token :

//         var ls_user = JSON.parse(localStorage.getItem('user'));        
//         const authToken = ls_user.token;

//         if (authToken) 
//         {
//             config.headers['x-auth-token'] = authToken;
//         }
//         return config;
//     },

//     error => { Promise.reject(error) }
// )

// bmAxiosClient.interceptors.response.use( 

//     response => {

//         // response.data üzerinde geçen ISO Date olan
//         // stringlerin Date objesine çevrilmesi :

//         convertStringDates(response.data);
//         return response;

//     },

//     error => {

//         // error.response.data üzerinde geçen ISO Date olan
//         // stringlerin Date objesine çevrilmesi :

//         if (error.response.data) convertStringDates(error.response.data);        
//         return Promise.reject(error);

//     }
// )

// export default bmAxiosClient;
