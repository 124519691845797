// Bu componentin altina alttaki şekilde menu item'lar koydugunda
// menuden seçim için çok şık görünüyor :

// <Menu.Item 
//     name='/Home' 
//     active={this.props.location.pathname === '/Home'}
//     onClick={this.onClick}
// > 

//     <BmMenuItem 
//         text={this.props.t("generalLabels.mainPage")}
//         icon="home"
//     />

// </Menu.Item>                                                

// Modal'den bir şey seçtireceksen <BmItemSelectionModal />
// komponentine bak


import React from 'react'
import { Menu, Divider } from "semantic-ui-react";
import { withRouter } from 'react-router-dom';
import BmAppLogo from './BmAppLogo';

class BmVertMenu extends React.Component{
state = {}

onLogoClick = () => {
    this.props.history.push('/');
}

render() {

return( 

    <div style={{
        display: "flex",
        flex: "1",
        flexDirection: "column",
        justifyContent: "flex-start"

    }}>

        {this.props.withAppLogo && 

            <a href={'/'}>

                <div>

                    <br />
                    <BmAppLogo/>
                    <br />        

                    <Divider clearing />

                </div>

            </a>

        }

        <div style={{
            flex: "1",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start"

        }}>

            <Menu  vertical fluid className="normal_border">    

                {this.props.children}


            </Menu>    
        </div>                    

    </div>





);
}
}
export default withRouter(BmVertMenu);