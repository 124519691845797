
// bir js degiskeninin tipini doner : 
export function getObjType(obj)
{
    var lv_obj_text = Object.prototype.toString.call(obj);

    switch (lv_obj_text) {
        case '[object Array]':  return "array";
        case '[object Object]': return "object";
        case '[object String]': return "string";
        case '[object Number]': return "number";
        case '[object Date]':   return "date";
    
        default: return lv_obj_text;
    }
}