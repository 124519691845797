import React from 'react';
import { Divider } from 'semantic-ui-react';

function BmSeparator(props){
return (

    <Divider clearing />
)
}
export default BmSeparator;
