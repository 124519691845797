// Semantic UI Ready Colors:
// <Button color='red'>Red</Button>
// <Button color='orange'>Orange</Button>
// <Button color='yellow'>Yellow</Button>
// <Button color='olive'>Olive</Button>
// <Button color='green'>Green</Button>
// <Button color='teal'>Teal</Button>
// <Button color='blue'>Blue</Button>
// <Button color='violet'>Violet</Button>
// <Button color='purple'>Purple</Button>
// <Button color='pink'>Pink</Button>
// <Button color='brown'>Brown</Button>
// <Button color='grey'>Grey</Button>
// <Button color='black'>Black</Button>

import React from 'react';
import { Button } from 'semantic-ui-react'
import BmParentSpacer from '../BmParentContainers/BmParentSpacer';

function BmButton({text, onClick, color="blue", icon=null, padding="5px", fluid=true, ...props }){

    return (

        <BmParentSpacer padding={padding}>

            <Button 
                {...props}
                content={text}
                onClick={onClick} 
                color={color}
                icon={icon}
                fluid={fluid}
                // circular

            />          

        </BmParentSpacer>
    )
}
export default BmButton;
