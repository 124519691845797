import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import tr from './Translations/tr';
import en from './Translations/en';
// import { tr } from 'date-fns/locale';

let lv_lang = 'tr';

// is it called like ?=lang=en 
const ls_params = parseQuery(document.location.search);

if (ls_params.lang === 'en')
{
    lv_lang = 'en';
    
}

i18n
.use(initReactI18next)
.init({

    resources: {
        tr: tr,
        en: en
    },

fallbackLng: lv_lang,
debug: false, //process.env.NODE_ENV !== 'production',
ns: ['translations'],
defaultNS: 'translations',
keySeparator: '.',
interpolation: {
    escapeValue: false,
    formatSeparator: ',',
},
react: {
    wait: true,
},
});

function parseQuery(search) {

    var args = search.substring(1).split('&');

    var argsParsed = {};

    var i, arg, kvp, key, value;

    for (i=0; i < args.length; i++) {

        arg = args[i];

        if (-1 === arg.indexOf('=')) {

            argsParsed[decodeURIComponent(arg).trim()] = true;
        }
        else {

            kvp = arg.split('=');

            key = decodeURIComponent(kvp[0]).trim();

            value = decodeURIComponent(kvp[1]).trim();

            argsParsed[key] = value;
        }
    }

    return argsParsed;
}

export default i18n;