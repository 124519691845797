import React from 'react'
import BmHeader from '../../../BmLibrary/BmComponents/BmHeaders/BmHeader';
import { withTranslation } from 'react-i18next';
import BmParentSpacer from '../../../BmLibrary/BmComponents/BmParentContainers/BmParentSpacer';
import BmParentAnimator from '../../../BmLibrary/BmComponents/BmParentContainers/BmParentAnimator';
import BmCreateButton from '../../../BmLibrary/BmComponents/BmButtons/BmCreateButton';
import BmModal from '../../../BmLibrary/BmComponents/BmModals/BmModal';
import OperForm from './OperForm';
import operApi from '../../API/operApi';
import { get_node_return_error } from '../../../BmLibrary/BmAxiosApi/BmNodeHandlingFunctions';
import BmMessage from '../../../BmLibrary/BmComponents/BmMessages/BmMessage';
import BmTable from '../../../BmLibrary/BmComponents/BmTable';
import BmSegment from '../../../BmLibrary/BmComponents/BmParentContainers/BmSegment';
import BmConfirm from '../../../BmLibrary/BmComponents/BmModals/BmConfirm';
import BmErrorModal from '../../../BmLibrary/BmComponents/BmModals/BmErrorModal';
 
class OperEntryPage extends React.Component{

initialState = 
{
    posting: false,
    openEntryForm: false,

    showDeleteConfirm: false,
    deleteConfirmText: "",

    infoMess: "",
    error: "",
    activeItem: {
        _id: "",
        operText: ""
    },
    items: []

}

state = {...this.initialState}

componentDidMount = async() => {

    this.setState({posting: true})

    try {

        const ls_response = await operApi.getOpers();

        this.setState({posting: false, items: ls_response.data});
        
    } catch (error) {
        
        const lv_error_mess = get_node_return_error(error);
        this.setState({posting: false, error: lv_error_mess});        

    }

}

onTableLineClick = (ip_fcode, ip_index) => {    

    if (ip_fcode === "edit")
    {
        this.setState({
            activeItem: this.state.items[ip_index],
            openEntryForm: true,
            showDeleteConfirm: false
        })
    }
    else if (ip_fcode === "delete")
    {

        this.setState({
            activeItem: this.state.items[ip_index],
            showDeleteConfirm: true,
            deleteConfirmText: this.props.t("operations.operDeleteQuestion", {operText: this.state.items[ip_index].operText}),
            openEntryForm: false
        })
    }
}

deleteOper = async() => {

    this.setState({
            posting: true,        
            showDeleteConfirm: false, 
            deleteConfirmText: "",

            infoMess: "",
            error: "",
            openEntryForm: false,            
        })

    try {

        const ls_response = await operApi.deleteOper(this.state.activeItem._id);

        this.setState({
            posting: false,
            items: ls_response.data,
            infoMess: this.props.t("operations.operDeleted", {operText: this.state.activeItem.operText})
        })
        
    } catch (error) {

        console.log('delete error:', error);

        const lv_error_mess = get_node_return_error(error);

        console.log('lv_error_mess:', lv_error_mess);
        this.setState({posting: false, error: lv_error_mess});                
    }
    
}

onNewEntryClick = () => {
    this.setState({
        activeItem: this.initialState.activeItem,
        openEntryForm: true
    })
}

onFormSubmit = async(values) => {

    this.setState({openEntryForm: false, posting: true, infoMess: "", error: ""})    

    try {

        const ls_response = await operApi.saveOper(values);

        this.setState({
            posting: false, 
            infoMess: this.props.t("operations.operSaved"),
            items: ls_response.data
        });
        
    } catch (error) {

        const lv_error_mess = get_node_return_error(error);
        this.setState({posting: false, error: lv_error_mess});        
    }
}

render() {
return( 
    <>
        <BmParentSpacer>
        <BmParentAnimator>

            <>

            <BmHeader 
                title={this.props.t("operations.operEntryTitle")} 
                icon="list alternate"
            />  

            <BmMessage 
                message={this.state.infoMess} visible={this.state.infoMess}
                success={true}
            />

            <BmErrorModal 
                visible={this.state.error} 
                text={this.state.error}
                onClose={ () => this.setState({error: ""})}
            />

            <BmSegment 
                headerText={this.props.t("operations.existingOpers") + " :"}
            >

                <BmTable
                    maxHeight='55vh'
                    it_records={this.state.items}
                    it_fcat={
                        [
                            {fieldname: "operText", title: this.props.t("operations.operText")},

                            {
                                fieldname: "button_edit",    
                                title: this.props.t("BmLabels.change"), 
                                function_code: "edit", 
                                button_icon: "edit",
                                button_color: "teal"
                            },

                            {
                                fieldname: "button_delete",    
                                title: this.props.t("BmLabels.delete"), 
                                function_code: "delete", 
                                button_icon: "trash alternate outline",
                                button_color: "red"
                            }
                        ]
                    }

                    onLineClick={this.onTableLineClick}         
                />

                <BmCreateButton onClick={ () => this.onNewEntryClick() }/>                

            </BmSegment>
            </>
                
        </BmParentAnimator> 
        </BmParentSpacer>          

    <BmModal visible={this.state.openEntryForm}>

        <OperForm
            formValues={this.state.activeItem}
            onSubmit={(values) => this.onFormSubmit(values)}
            posting={this.state.posting}
            onCloseModal={() => this.setState({openEntryForm: false})}
        />

    </BmModal>

    <BmConfirm 
        visible={this.state.showDeleteConfirm}
        text={this.state.deleteConfirmText}
        onCancel={ () => this.setState({showDeleteConfirm: false, deleteConfirmText: ""})}
        onConfirm={ () => this.deleteOper()}
    />
        
    </>

);
}
}

export default withTranslation()(OperEntryPage);
