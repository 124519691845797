import React from 'react'
import { Grid, Sidebar, Segment, Menu, Divider } from "semantic-ui-react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import BmTopMenu from './BmTopMenu';
import BmVertMenu from './BmVertMenu';
import BmAppLogo from './BmAppLogo';

class BmDashboard extends React.Component{
state = {
    SidebarVisible: true
}

sidebarToggle = () => {
    var lv_bool = !this.props.mobileSidebarOpen;

    this.props.redux_dispatch({type: 'SET_MOB_SIDEBAR', mobileSidebarOpen: lv_bool});
}

render() {

        return(

        <div style={{flex: "1", minHeight: "100vh"}} id="dashboard_main_div">
        {/* <Container fluid style={ls_container_style}> */}

            {this.props.mobileView ?
            (
                <div>
                    <Sidebar.Pushable as={Segment} style={{flex: "1", minHeight: "100vh"}}>

                        <Sidebar
                        as={Menu}
                        animation='push'
                        icon='labeled'
                        // inverted
                        onHide={this.sidebarToggle}
                        vertical
                        visible={this.props.mobileSidebarOpen}
                        width='thin'
                        >

                            {/* <VertMenuItems /> */}

                            <div>

                                <br />
                                <BmAppLogo/>
                                <br />        

                                <Divider clearing />

                                {this.props.children[0]}

                            </div>

                        </Sidebar>

                        <Sidebar.Pusher dimmed={this.props.mobileSidebarOpen}>

                            <BmTopMenu/>         
                            {/* <RoutesForRole/> */}

                            <div style={{maxHeight: "90vh", overflowY: "auto"}}>
                                {this.props.children[1]}
                            </div>

                        </Sidebar.Pusher>
                    </Sidebar.Pushable>            
                </div>

            )
            :
            (

                <Grid style={{flex: "1", minHeight: "100vh"}} id="grid_main_pane">

                    {this.props.Bm_grid_settings.MenuVisible && 
        
                        <Grid.Column width={3} 
                            // style={{paddingRight: 0, 
                            //         border: "1px solid rgba(0, 0, 0, 0.05)" }}                            
                            className="gen_box_shadow"
                        >
                
                            <BmVertMenu withAppLogo={true}>

                                {/* <VertMenuItems /> */}
                                {this.props.children[0]}

                            </BmVertMenu>
                
                        </Grid.Column>
                    }
            
                    <Grid.Column 
                        width={this.props.Bm_grid_settings.contentColWidth} 
                        style={{
                            paddingLeft: 0, 
                            flex: "1", 
                            minHeight: "100vh"
                        }}>

                        <div style={{paddingLeft:"10px"}}>
                            <BmTopMenu/>                            
                        </div>

                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            // justifyContent: "center",
                            // alignItems: "center",
                            flex: "1",
                            maxHeight: "90vh", 
                            overflowY: "auto"
                        }}
                        >

                            {/* <RoutesForRole/> */}                            
                        
                            {this.props.children[1]}

                        </div>

                    </Grid.Column>
            
                </Grid>
      
            )
        
            }

        {/* </Container> */}
        </div>            
        );
    }
}

const mapStateToProps = (state) => {
return {
    mobileView: state.mobileView,
    mobileSidebarOpen: state.mobileSidebarOpen,
    token_exists : !!state.user.token,
    Bm_grid_settings: state.Bm_grid_settings,
    user: state.user
    };
};
const mapDispatchToProps = (dispatch) => {
return {
    redux_dispatch : (ip_action) => dispatch(ip_action)
        };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BmDashboard));

