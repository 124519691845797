import React from 'react'
import {Image} from 'semantic-ui-react';
import { Link }  from 'react-router-dom';

class BmAppLogo extends React.Component{
state = {}
render() {

    var lv_style_padding = {
        paddingLeft: "3px"
      }       

    var lv_size = 'small';
    if (this.props.size)
    {
        lv_size = this.props.size;
    }

return( 
    <div> 
        <Link to="/"> 
            <Image src='/applogo/AppLogo_Text.png' size={lv_size} centered style={lv_style_padding}/>    
        </Link>                    
    </div>
);
}
}
export default BmAppLogo;