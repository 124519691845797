export function get_node_return_error(ip_error, ip_i18next) {

    // if there is a server response :
    if (ip_error.response)
    {
        // validation error with fieldname :
        if (ip_error.response.data.fieldname)
        {
            return ip_error.response.data.message;
        }
        else if (ip_error.response.data.message)
        {
            return ip_error.response.data.message;
        }
        else
        {
            return ip_error.response.request.responseText;
        }
        
    }
    else if (ip_error.message)
    {
        // no server response like "Network Error"
        return ip_error.message;
    }
    else
    {
        var lv_message  = ip_i18next.t('BmLabels.errorOccured');
        return lv_message;
    }

}