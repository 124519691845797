import React from 'react';
import { Button } from 'semantic-ui-react';
import BmHeader from '../BmHeaders/BmHeader';
 
class BmCollapsiblePane extends React.Component{
state = {
    icon: "chevron down",
    contentOpen: false
}

onChevronClick = () => {

    if (this.state.contentOpen)
        this.setState({contentOpen: false, icon: "chevron down"})
    else
        this.setState({contentOpen: true, icon: "chevron up"})

}

render() {
return( 
    <>

        <div style={{
            display: "flex", 
            flexDirection:"row", 
            justifyContent:"flex-start", 
            alignItems:"center"}}
        >

            <Button 
                circular 
                icon={this.state.icon} 
                onClick={ () => this.onChevronClick() }
            />

            <BmHeader title={this.props.title} inBlock={false} size="h3"/>

        </div>

        {this.state.contentOpen &&

            <>
                {this.props.children}
            </>

        }

        
    </>
);
}
}
export default BmCollapsiblePane;