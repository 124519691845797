import React from 'react'
import { Dropdown, Form, Label } from 'semantic-ui-react';

import { withTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

// verdigin t_options tablosundaki değerler ile dropdown field açar
// t_options'da 3 alanin olmali :

// const ls_listbox_line = {
//    key: smbh_line._id,
//    text: smbh_line.name,
//    value: smbh_line._id
// }

// key : sadece siralama için
// text : görünecek metin
// value : form'a set edeceği değer

// ( klasik dropdown değil, modal içerisinde dropdown istersen
//   BmDropdownField komponentine bak )

function BmFormDropdownField({t, fieldname, t_options, icon="info", edit=true, withoutTopLabel=false, ...otherProps}){

    const {setFieldTouched, touched, errors, values, handleChange, setFieldValue} = useFormikContext();  
    
    const lv_showError = edit && touched[fieldname] && errors[fieldname];    

    const onChange = (event, data) => {

        setFieldValue(fieldname, data.value);

    }

return (

    <>

        <Form.Field error={lv_showError}>  

            { !withoutTopLabel &&

                <label htmlFor={fieldname} style={{textAlign: 'left'}}>
                    {otherProps.placeholder}
                </label>                    
            }

            <Dropdown
                fluid
                // icon={icon}
                placeholder={otherProps.placeholder}
                selection

                name={fieldname}
                value={values[fieldname]}

                onChange={ (event, data) => onChange(event, data)}
                // onChange={handleChange(fieldname)}
                onBlur={() => setFieldTouched(fieldname)}
                readOnly={!edit}
                options={t_options}                
            />       

            { lv_showError &&

            <Label basic color='red' pointing>
                {errors[fieldname]}
            </Label>        
            }

            </Form.Field>        

    </>

)
}
export default withTranslation()(BmFormDropdownField);
