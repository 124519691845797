import React, { useEffect } from "react";
import BmForm from '../../../BmLibrary/BmComponents/BmForms/BmForm';
import BmFormField from '../../../BmLibrary/BmComponents/BmForms/BmFormField';
import BmFormSubmitButton from '../../../BmLibrary/BmComponents/BmForms/BmFormSubmitButton';
import * as Yup from "yup";
import { withTranslation } from 'react-i18next';
import BmFormDateField from "../../../BmLibrary/BmComponents/BmForms/BmFormDateInputs/BmFormDateField";
import BmParentCollapsibleLine from "../../../BmLibrary/BmComponents/BmParentContainers/BmParentCollapsibleLine";
import BmParentSpacer from "../../../BmLibrary/BmComponents/BmParentContainers/BmParentSpacer";
import BmFormPhoneField from "../../../BmLibrary/BmComponents/BmForms/BmFormPhoneField";
import BmSegment from "../../../BmLibrary/BmComponents/BmParentContainers/BmSegment";
function PatientForm({
    t, 
    edit=true, 
    formValues, 
    posting, 
    onSubmit, 
    headerText, 
    padding=true, 
    asSearchForm,
    inSegment=true
    }){

    let validationSchema = null;

    if (asSearchForm)
    {
        // form arama için kullaniliyor ise validasyon yok
    }
    else
    {
        // kayıt atma için kullaniliyor ise validasyonlar devreye girer

        validationSchema = Yup.object().shape({
        
            name: Yup.string().required(t("patientMaster.enterName")),      // lütfen hasta adını girin 
            surname: Yup.string().required(t("patientMaster.enterSurname")),  // lütfen hasta soyadını girin 
            phone: Yup.string().required(t("patientMaster.enterPhone")),  // lütfen telefon no girin
            email: Yup.string().email(t("generalLabels.enterValidEmail")),
            // email: Yup.string().required(t("generalLabels.enterEmail")).email(t("patientMaster.enterValidEmail")),
            birthDate: Yup.date().required(t("patientMaster.enterBirthDate")).nullable() // lütfen doğum tarihi girin
    
            // password: Yup.string().required('Lütfen şifreni seç'),
            // passwordRepeat: Yup.string().required('Lütfen şifreni tekrar gir')        
    
        })

    }

    const onSubmitLocal = (values) => {

        console.log('onSubmitLocal.values:', values);

        if (values.phone)
        {
            if (values.phone === '90')
            values.phone = '';
        }
        onSubmit(values);

    }

    useEffect( () => {

        // console.log('formValues:', formValues);
        // console.log('posting:', posting);
    },

    [formValues, posting]);

return (

    <>

    <BmParentSpacer passive={!padding}>

        <BmSegment 
            headerText={headerText} 
            color="blue"
            visible={inSegment}
        >

        <BmForm
            initialValues={formValues}
            onSubmit={(values) => onSubmitLocal(values)}    
            validationSchema={validationSchema}
            // formHeaderText={headerText}
            loading={posting}
            inSegment={false}
        >

            <BmParentCollapsibleLine>

                <BmFormField
                    fieldname="name"
                    placeholder={t("generalLabels.name") + ":"}
                    edit={edit}
                />

                <BmFormField
                    fieldname="surname"
                    placeholder={t("generalLabels.surname") + ":"}
                    edit={edit}            
                />                  

            </BmParentCollapsibleLine>

            <BmParentCollapsibleLine>

                <BmFormDateField 
                    fieldname="birthDate"
                    placeholder={t("generalLabels.birthDate") + ":"}       
                    edit={edit}            
                />

                <BmFormField
                    fieldname="nationalID"
                    placeholder={t("generalLabels.nationalID") + ":"}       
                    icon="male"
                    edit={edit}            
                />                  

            </BmParentCollapsibleLine>        

            <BmParentCollapsibleLine>

                {/* <BmFormField
                    fieldname="phone"
                    placeholder={t("generalLabels.phone") + ":"}       
                    icon="phone volume"
                    edit={edit}            
                />                   */}

                <BmFormPhoneField
                    fieldname="phone"
                    placeholder={t("generalLabels.phone") + ":"}       
                    edit={edit}
                />


                <BmFormField
                    fieldname="email"
                    placeholder={t("generalLabels.email") + ":"}       
                    icon="mail"
                    edit={edit}            
                />                  

            </BmParentCollapsibleLine>

            <BmFormField
                fieldname="address"
                placeholder={t("generalLabels.address") + ":"}       
                icon="home"
                edit={edit}            
            />                  

            <BmFormField
                fieldname="city"
                placeholder={t("generalLabels.city") + ":"}       
                icon="map"
                edit={edit}            
            />                 

            {edit && 
                <BmFormSubmitButton asSearchButton={asSearchForm} />        
            } 

        </BmForm>
        </BmSegment>

    </BmParentSpacer>

    </>
)
}

export default withTranslation()(PatientForm);