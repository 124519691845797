import React from 'react';
import { Menu } from "semantic-ui-react";
import BmVertMenu from '../../BmDashboard/BmVertMenu';
import BmCloseButton from '../BmButtons/BmCloseButton';
import BmMenuItem from '../BmMenuItem';
import BmErrorMessage from '../BmMessages/BmErrorMessage';
import BmMessage from '../BmMessages/BmMessage';
import BmSegment from '../BmParentContainers/BmSegment';
import BmSeparator from '../BmSeparator';
import BmModal from './BmModal';

function BmItemSelectionModal({
    t_items, 
    idField="_id", 
    columnTextField="text", 
    visible, 
    onClick,
    onCancel,
    headerText="",

    errorMessage="",
    loadingMessage=""
    })
{

    var lt_content = [];

    for (const ls_item of t_items) {

        var ls_item_html = 
        <Menu.Item 
            name={ls_item[idField]} 
            onClick={ () => onClick(ls_item)}
        > 

            <BmMenuItem 
                text={ls_item[columnTextField]}
                icon="angle right"
            />

        </Menu.Item>           

        lt_content.push(ls_item_html);

    }

    if (errorMessage)
    {
        return (
            <BmModal visible={visible}>

                <BmErrorMessage
                    message={errorMessage} 
                />

            </BmModal>
        )
    }

    else if (loadingMessage)
    {
        return(

            <BmModal visible={visible}>

                <BmMessage
                    message={loadingMessage}
                />

            </BmModal>            

        )
    }


    return (

    <BmModal visible={visible}>

        <BmSegment headerText={headerText}>

            <div style={{maxHeight: "60vh", overflow: "auto"}}>
            
                <BmVertMenu>

                    {lt_content}

                </BmVertMenu>                

            </div>

            <BmSeparator />

            <BmCloseButton onClick={ () => onCancel()} />

        </BmSegment>
        
    </BmModal>

)
}
export default BmItemSelectionModal;
