import React from 'react';
import './HomePage.css';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import BmParentAnimator from '../../../BmLibrary/BmComponents/BmParentContainers/BmParentAnimator';

import BmParentSpacer from '../../../BmLibrary/BmComponents/BmParentContainers/BmParentSpacer';
import BmIconHeader from '../../../BmLibrary/BmComponents/BmHeaders/BmIconHeader';
import BmSeparator from '../../../BmLibrary/BmComponents/BmSeparator';
import BmHeader from '../../../BmLibrary/BmComponents/BmHeaders/BmHeader';
import BmIconLink from '../../../BmLibrary/BmComponents/BmButtons/BmIconLink';
import BmParentCollapsibleLine from '../../../BmLibrary/BmComponents/BmParentContainers/BmParentCollapsibleLine';

class HomePage extends React.Component{

    state= {
        timeValue: new Date(),
        intervalId: 0
    }

    componentDidMount = () => {
        const lv_intervalId = setInterval(
            () => 
            {
                const lv_date = new Date();
                this.setState({
                    timeValue: lv_date
                })
            }, 

            1000);

        this.setState({intervalId: lv_intervalId})
    }

    componentWillUnmount(){
        clearInterval(this.state.intervalId);
      }    

render() {
 
return(

    <BmParentAnimator>
    <BmParentSpacer>

        <div style={{
            flex: "1",
            display: "flex",
            minHeight: "80vh",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center"
        }}>
        
            <BmIconHeader 
                icon="doctor"
                headerText={this.props.user.clinicName}
                // subText={this.state.timeValue.toLocaleString('tr-TR')} 
                size="h1"
                // iconColor='teal'
            />
                        
            <BmParentCollapsibleLine>

                <BmIconLink 
                    icon="search"
                    text={this.props.t("generalLabels.genSearch")}
                    route="/genSearch"
                />

                <BmIconLink 
                    icon="sticky note outline"
                    text={this.props.t("patientMaster.newPatientTitle")}
                    route="/patientEntry"
                />

                <BmIconLink 
                    icon="calendar alternate outline"
                    text={this.props.t("visits.visitEntyTitle")}
                    route="/visitEntry"
                />


                {/* <BmButton 
                    text={this.props.t("patientMaster.patientSearchTitle")}
                    onClick={() => this.props.history.push({pathname: '/patientSearch'})}
                    icon="search"
                    // color="teal"
                    basic
                />

                <BmButton 
                    text={this.props.t("patientMaster.newPatientTitle")}
                    onClick={() => this.props.history.push({pathname: '/patientEntry'})}
                    icon="sticky note outline"
                    color="blue"
                />

                <BmButton 
                    text={this.props.t("visits.visitEntyTitle")}
                    onClick={() => this.props.history.push({pathname: '/visitEntry'})}
                    icon="calendar alternate outline"
                    color="white"
                />
                */}


            </BmParentCollapsibleLine>            

            <BmSeparator />

            <BmHeader 
                icon="clock outline"
                title={this.state.timeValue.toLocaleString('tr-TR')}  
                inBlock={false}
                color="grey"    
            />            
            
        </div>

     </BmParentSpacer>
     </BmParentAnimator>

    )        
}
}

const mapStateToProps = (state) => {
return {
    user: state.user,
    mobileView : state.mobileView

    };
};

export default connect(mapStateToProps)(withTranslation()(HomePage));
