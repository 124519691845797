import { parseISO } from 'date-fns';
import { getObjType } from './BmDynamicFunctions';

const gc_isoUTCSuffix = "T00:00:00.000Z"

// ISO Date ornek :  2022-02-01T00:00:00.000Z


export function getDayString(ip_date){

    // bir ISO Date alanından DD.MM.YYY formatında tarih metni üretir :    
    if (!ip_date) return null;    

    const lv_first_10 = getYearMonthDay(ip_date);
    // .toISOString() bazen otomatik UTC çevirimi yaptığı için üstteki fonk. yazdik 

    // lv_first_10 : 2022-02-23

    const lt_array = lv_first_10.split("-");
    const lv_result = lt_array[2] + '.' + lt_array[1] + '.' + lt_array[0];

    return lv_result;
}


export function getYearMonthDay(ip_date){

// Gelen bir date objesinden YYYY-MM-DD formatında sadece tarih çıkarır

// !! date.toISOString()  -bazen- verdiğin tarihi UTC'ye çevirerek
//    yani 3 saat geriye alıp günün değişmesine sebep olarak yazabiliyor
//    bunu ne zaman yapacağı belli değil .. bazen oluyor
//    o sebeple herhangi bir çevrim yapmadan alttaki şekilde aldım :

    var lv_year  = ip_date.getFullYear();
    var lv_month = ip_date.getMonth() + 1;
    var lv_day   = ip_date.getDate();

    var lv_month_string = lv_month.toString();

    if (lv_month_string.length === 1)
        lv_month_string = "0" + lv_month_string;

    var lv_day_string = lv_day.toString();        

    if (lv_day_string.length === 1)
        lv_day_string = "0" + lv_day_string;

    return lv_year + "-" + lv_month_string + "-" + lv_day_string;
}

export function toOnlyDate_UTC({ip_date=null, ip_isoString=null}={}){

    // bir Date ya da ISOString objesinden
    // --sadece Date içeren -- time kismi 00:00:00 olan
    //   ve UTC zaman diliminde olan Date objesine çevirir
    // (saat ve zaman dilimi ile ilgilenmeden sadece o anki tarihi aldım,
    //   ve UTC olarak kabul edip JS'in zaman dilimine göre tarih ile oynamasından kurtuldum) :

    // !! new Date() icine "T00:00:00.000Z" yani UTC ekini koymazsan
    //    girdigin metin degeri local time zone olarak aliyor
    //    ve buna göre UTC yani 3 saat öncesini Date olarak üretiyor
    //    01.01.1980 verirsen, 31.12.1979'a çeviriyor ..

    let lv_ymd = "";  // YYYY-MM-DD metni

    if (ip_date)   
        lv_ymd = getYearMonthDay(ip_date)    // frontend de date girildiği anda, hiç bir UTC çevrimine sokmadan yıl ay gün almak için
    else if (ip_isoString)
        lv_ymd = ip_isoString.slice(0, 10);   // backend'de gelen metin alanarı zaten UTC ve ISOString geliyor

    const lv_date_string = lv_ymd + gc_isoUTCSuffix;

    return new Date(lv_date_string);
}


// bir string Date mi icerir ? : 
export function isStringISODate(_date) {
    const _regExp  = new RegExp('^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$');
    return _regExp.test(_date);
}

// bir JS objesi ya da arrayi + bunların tüm alt objelerinde
// string türünde olup içinde ISO formatında tarih yazan bir degisken varsa
// bunu Date objesine cevirir ( axios api uzerinde kullanmıştık ) : 
export function convertStringDates(cp_object, log=false)
{
    const lv_obj_type = getObjType(cp_object);

    if (log) console.log('cp_object:', cp_object, 'type:', lv_obj_type);

    switch (lv_obj_type) {
        case 'array' :
        {

            if (log) console.log('object:', cp_object, 'is an array .. converting line by line');

            for (const ls_line of cp_object) {

                convertStringDates(ls_line);

            }
        }
        case 'object' :            
        {
            for (const key of Object.keys(cp_object)) {

                const lv_sub_obj_type = getObjType(cp_object[key]);

                if (log) console.log('key:', key, ' of object:', cp_object[key], ' has type: ', lv_sub_obj_type);

                if (lv_sub_obj_type === 'string')
                {
                    if (isStringISODate(cp_object[key]) === true)
                    {
                        cp_object[key] = parseISO(cp_object[key])
                    }    
                }
                else
                {
                    convertStringDates(cp_object[key])
                }
            }
        }

        case 'string' :
        {

            if (isStringISODate(cp_object) === true)
            {
                if (log) console.log('string:', cp_object, 'is a date .. converting ..');
                parseISO(cp_object)
            }
            else
            {
                if (log) console.log('string:', cp_object, 'is a not a date');                
            }

        }
    }
}
