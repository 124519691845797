import React from 'react';
import BmFormDropdownField from '../BmFormDropdownField';
import { withTranslation } from 'react-i18next';

function BmFormDropdownMonths({t, fieldname, placeholder, withoutTopLabel=false, icon }){
return (

    <BmFormDropdownField
        fieldname={fieldname}
        placeholder={placeholder}
        // icon="map"

        withoutTopLabel={withoutTopLabel}

        t_options={[
            {key: "00", text: "",                        value:""},            
            {key: "01", text: t("BmLabels.January"),     value:"01"},
            {key: "02", text: t("BmLabels.February"),    value:"02"},
            {key: "03", text: t("BmLabels.March"),       value:"03"},
            {key: "04", text: t("BmLabels.April"),       value:"04"},
            {key: "05", text: t("BmLabels.May"),         value:"05"},
            {key: "06", text: t("BmLabels.June"),        value:"06"},
            {key: "07", text: t("BmLabels.July"),        value:"07"},
            {key: "08", text: t("BmLabels.August"),      value:"08"},
            {key: "09", text: t("BmLabels.September"),   value:"09"},
            {key: "10", text: t("BmLabels.October"),     value:"10"},
            {key: "11", text: t("BmLabels.November"),    value:"11"},
            {key: "12", text: t("BmLabels.December"),    value:"12"}        
        ]}

    />    

)
}
export default withTranslation()(BmFormDropdownMonths);
