import getSettings from "./settings";
const { default: createBmAxiosClient } = require("../BmLibrary/BmAxiosApi/bmAxiosClient");

// Uygulamaya özel ayarları oku
// ( dev/prog için timeOut, basURL vb)  :
const ls_apiSettings = getSettings();

// bmAxiosClient objesini bu ayarlar ile oluştur :
const bmAxiosClient = createBmAxiosClient(ls_apiSettings)

export default bmAxiosClient;