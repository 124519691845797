import React from 'react';
import { useFormikContext } from 'formik';
import { Form, Label  } from "semantic-ui-react";

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import tr from 'react-phone-input-2/lang/tr.json'

function BmFormPhoneField({fieldname, edit=true, ...otherProps}){

    const {setFieldTouched, touched, errors, values, handleChange} = useFormikContext();

    const lv_showError = edit && touched[fieldname] && errors[fieldname];

    const ls_input_props = {name: fieldname};
    if (!edit) ls_input_props.readOnly = "true";

return (

    <>
        <Form.Field error={lv_showError}>    

        <label htmlFor={fieldname} style={{textAlign: 'left'}}>
            {otherProps.placeholder}
        </label>                        

        <PhoneInput
            country={'tr'}
            onlyCountries={["tr"]}
            countryCodeEditable={false}
            localization={tr}
            placeholder=""
            value={values[fieldname]}
            onChange={handleChange(fieldname)}
            onBlur={() => setFieldTouched(fieldname)}
            inputProps={ls_input_props}

            inputStyle={{paddingLeft: "45px"}}
            
        />


        {/* <Form.Input
            fluid
            icon={icon}
            iconPosition='left'
            placeholder={otherProps.placeholder}
            type='text'
            name={fieldname}
            value={values[fieldname]}
            // onChange={(text) => setFieldValue(fieldname, text)}
            onChange={handleChange(fieldname)}
            onBlur={() => setFieldTouched(fieldname)}
            readOnly={!edit}
        /> */}

        { lv_showError &&

            <Label basic color='red' pointing>
                {errors[fieldname]}
            </Label>        
        }

        </Form.Field>        

    </>

)
}
export default BmFormPhoneField;
