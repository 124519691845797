import React from 'react';
import { Icon } from 'semantic-ui-react'

function BmIcon({iconName, disabled=false, size="large"}){
return (

    <Icon name={iconName} disabled={disabled} size={size}/>

)
}

export default BmIcon;