import React from 'react';
import { Link }  from 'react-router-dom'; 
import { Icon } from 'semantic-ui-react';
import BmHeader from '../BmHeaders/BmHeader';

class BmIconLink extends React.Component{
state = {}
render() {


    var lv_iconColor = "blue";
    // var lv_textColor = "black";

    if (this.props.iconColor)
        lv_iconColor = this.props.iconColor;

    // if (this.props.textColor)
    //     lv_textColor = this.props.textColor;


return( 
    <div> 
        <Link to={this.props.route}> 

            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                // border: "1px solid gainsboro",
                padding: "8px"
            }}>

                <Icon 
                    name={this.props.icon} 
                    color={lv_iconColor}
                    size="huge"
                    circular
                    inverted
                />

                <BmHeader 
                    title={this.props.text} 
                    inBlock={false}
                    color={this.props.textColor}
                />

            </div>

        </Link>                    
    </div>

);
}
}
export default BmIconLink;