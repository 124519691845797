import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, Menu, Icon, Responsive, Dropdown} from "semantic-ui-react";
import { Link }  from 'react-router-dom';
import BmAppLogo from './BmAppLogo';

class BmTopMenu extends React.Component{
state = {

    sidebarVisible: true

}

componentDidMount = () => {

    // if user has not logged in yet
    // then side/vertical menu is not visible 
    // Only Logon / Sign-up bar on top menu is visible :

    if (!this.props.user.token)
    {

        this.props.redux_dispatch(
            {type: 'FS_GRID_TOOGLE', 
            Bm_grid_settings: {MenuVisible: false, contentColWidth: 16}
        });

    }
}

logout = () => {

    localStorage.removeItem("user");
    // this.props.history.push("/");
    this.props.redux_dispatch({type: 'USER_LOGGED_OUT', user: {}});

    // hide vertical menu for full screen : 
    this.props.redux_dispatch(
        {type: 'FS_GRID_TOOGLE', 
        Bm_grid_settings: {MenuVisible: false, contentColWidth: 16}
        });

    this.props.history.push('/');
    window.location.reload();     
  

}
sidebarToggle = () => {

    if (this.props.mobileView)
    {
        var lv_bool = !this.props.mobileSidebarOpen;
        this.props.redux_dispatch({type: 'SET_MOB_SIDEBAR', mobileSidebarOpen: lv_bool});
    }
    else
    {
        lv_bool = !this.props.Bm_grid_settings.MenuVisible;
        if (lv_bool)
        {
            var lv_content_col_width = 13;
        }
        else
        {
            lv_content_col_width = 16;
        }
        this.props.redux_dispatch(
            {type: 'FS_GRID_TOOGLE', 
            Bm_grid_settings: {MenuVisible: lv_bool, contentColWidth: lv_content_col_width}
        });

    }
}

handleSizeChange = (event, data) =>{

    const lv_curr_width = data.getWidth();

    if (lv_curr_width <= this.props.mobileWidth)
    {
        this.props.redux_dispatch({type: 'SET_MOB_VIEW', mobileView: true})
    }
    else
    {
        this.props.redux_dispatch({type: 'SET_MOB_VIEW', mobileView: false})            
    }

    this.props.redux_dispatch({type: 'SET_CURRENT_WIDTH', currWidth: lv_curr_width})    
}

render() {

    const lv_style_noPadding = {
        padding: 5 
      }    

    if (this.props.user.token)
    {
        return( 
            <div>

            <Menu fluid borderless className="normal_border">

                <Menu.Item position='left' className="menu_item_narrow">

                    <Responsive fireOnMount onUpdate={this.handleSizeChange}> 
                        <Button icon basic className='button_no_border' onClick={this.sidebarToggle}>
                            <Icon name="content" />                              
                        </Button>                  
                    </Responsive>                              

                </Menu.Item>        

                <Menu.Item position='right'>

                    <Icon name='user' color='teal' size='small' circular inverted />            

                    <Dropdown inline item text={this.props.user.name} style={lv_style_noPadding}>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={this.logout}>
                            {this.props.t("BmLabels.logout")}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                    </Dropdown>            

                </Menu.Item>

            </Menu>

            </div>

        );

    }
    else
    {

        {/**************** Top Menu For Public Visitors ****************/}        

        return(


        <div>

            <Menu size="large" fluid borderless className="normal_border">

            <Menu.Item position='left' className="menu_item_narrow">

                {this.props.mobileView  &&

                    <Button icon basic className='button_no_border' onClick={this.sidebarToggle}>
                        <Icon name="content" />                              
                    </Button>                 
                
                }

                <Responsive fireOnMount onUpdate={this.handleSizeChange} minWidth={this.props.mobileWidth}> 
                    <BmAppLogo />
                </Responsive>      

            </Menu.Item>        

            <Menu.Menu position="right">

            <Menu.Item position='right'>
                <Link to="/logon"> {this.props.t("BmLabels.logon")} </Link>                        
            </Menu.Item>        

            </Menu.Menu>

            </Menu>

        </div>

        )

    }

}
}

const mapStateToProps = (state) => {
return {
    mobileWidth: state.mobileWidth,
    mobileView: state.mobileView,
    mobileSidebarOpen: state.mobileSidebarOpen,
    user: state.user,
    Bm_grid_settings: state.Bm_grid_settings    
};
};


const mapDispatchToProps = (dispatch) => {
return {
    redux_dispatch : (ip_action) => dispatch(ip_action)
        };
};

export default withRouter( 
    connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BmTopMenu)) 
    );
