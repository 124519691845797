import React from 'react';
import { withTranslation } from 'react-i18next';
import BmCloseButton from '../../../BmLibrary/BmComponents/BmButtons/BmCloseButton';
import BmForm from '../../../BmLibrary/BmComponents/BmForms/BmForm';
import BmFormField from '../../../BmLibrary/BmComponents/BmForms/BmFormField';
import BmFormSubmitButton from '../../../BmLibrary/BmComponents/BmForms/BmFormSubmitButton';
import BmParentSpacer from '../../../BmLibrary/BmComponents/BmParentContainers/BmParentSpacer';

function OperForm({t, edit=true, formValues, posting, onSubmit, headerText, padding=true, onCloseModal}){
return (
    <>

    <BmParentSpacer passive={!padding}>

        <BmForm
            initialValues={formValues}
            onSubmit={(values) => onSubmit(values)}    
            // validationSchema={validationSchema}
            formHeaderText={headerText}
            loading={posting}
        >

            <BmFormField
                fieldname="operText"
                placeholder={t("operations.operText") + ":"}
                edit={edit}
            />

            {edit && 
                <BmFormSubmitButton />        
            } 

            <BmCloseButton onClick={ () => onCloseModal()} />

        </BmForm>            

    </BmParentSpacer>
    
    </>

)
}

export default withTranslation()(OperForm);
