import React from 'react'
import BmExceptionScreen from './BmExceptionScreen';

import bmErrorApi from './BmErrorApi';import { BmGetBrowserType } from '../BmFunctions/BmGetBrowserType';

class BmErrorBoundry extends React.Component{

state = {
    hasError: false,
    errorMessage: "",
    componentStack: ""
};

static getDerivedStateFromError = error => {
    return { hasError: true };
}; 

componentDidCatch = async(error, info) => {

    this.setState({ error, info });

    const ls_logData = {
        errorMessage: error.message,
        componentStack:  info.componentStack,
    }

    try {

        await bmErrorApi.post(ls_logData)
        
    } catch (error) {
        console.log('error logging exception:', error);   
    }

};

render() {

    if (BmGetBrowserType() === 'IE')
    {
       return (
 
           <div style={{paddingTop: "100px"}}> 
                <h1 style={{textAlign: "center"}}>Tarayıcınız Desteklenmiyor ..</h1>               
                <h2 style={{textAlign: "center"}}>
                    Google Chrome , Firefox, Edge gibi modern bir internet tarayıcı kullanınız
                </h2>               
           </div>
 
       )
       
    };
 

    if (this.state.hasError) {

        // Custom fallback UI :
        return <BmExceptionScreen />
    };

    return this.props.children;
}    

}
export default BmErrorBoundry;