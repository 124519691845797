export function mobileWidth(state = 767, action) {
    switch (action.type) {
        	case 'SET_MOB_WIDTH':
                return action.mobileWidth;
            default:
                return state;
    	}
}

export function mobileView(state = false, action) {
    switch (action.type) {
        	case 'SET_MOB_VIEW':
                return action.mobileView;
            default:
                return state;
    	}
}

export function mobileSidebarOpen(state = false, action) {
    switch (action.type) {
        	case 'SET_MOB_SIDEBAR':
                return action.mobileSidebarOpen;
            default:
                return state;
    	}
}

export function currWidth(state = 0, action) {
    switch (action.type) {
        	case 'SET_CURRENT_WIDTH':
                return action.currWidth;
            default:
                return state;
    	}
}