import React from 'react';
import { Message } from "semantic-ui-react";

function BmMessage({message, subMessage, visible=true, success=false}){

    if (!visible || !message) return null;       

    // default : info message : blue box + info (I) icon :

    let lv_info = true;       // blue 
    let lv_positive = false;  // green
    let lv_icon = "info";

    // if success message is set :

    if (success)
    {
        lv_info = false;
        lv_positive = true; // green box 
        lv_icon = "check";  // okay sign
    }

    return (

        <Message 
            info={lv_info}
            positive={lv_positive}
            icon={lv_icon}
            header={message}
            content={subMessage} 
            size="small"
        />        
    )
}
export default BmMessage;
