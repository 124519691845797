import bmAxiosClient from "../../GenAPI/axiosClient";

const savePatient = (data) =>  bmAxiosClient.post('/api/patients/save', data);

const readPatientById = (id) => bmAxiosClient.get('/api/patients/' + id);

const searchPatient = (data) => bmAxiosClient.get('/api/patients', {params: data});        

const deletePatient = (id) => bmAxiosClient.delete('/api/patients/delete', { data:{id: id}});

export default {savePatient, readPatientById, deletePatient, searchPatient};
