import { Modal } from 'semantic-ui-react'

import React from 'react';
import BmHeader from '../BmHeaders/BmHeader';

function BmModal({visible, children, headerText}){
return (

    <Modal open={visible}>

        {headerText &&
            // <Modal.Header> {headerText} </Modal.Header>

            <BmHeader title={headerText} />
        }

        <Modal.Content>

            {children}

        </Modal.Content>

    </Modal>

)
}
export default BmModal;
