// örnek kullanım:

// <BmTable 
//     it_fcat={[
//         {fieldname: "name",           title: "Name"}, 
//         {fieldname: "surname",        title: "Surname"}, 
//         {fieldname: "city",           title: "City"}, 
//         {fieldname: "button_detail",  title: "Detay Görüntüle",  function_code: "dispDetail", button_color: "teal"}
//     ]} 

//     it_records={[
        
//         {
//             name: "ahmet",
//             surname: "atar",
//             city: "istanbul",
//         },

//         {
//             name: "sezai",
//             surname: "tutar",
//             city: "izmir",
//         }
            
//     ]}

//     onLineClick={(command, index) => this.onLineClick(command, index)}

// />

import React from 'react';
import { Button, Table } from 'semantic-ui-react';

function BmTable({
    it_records, 
    it_fcat, 
    onLineClick, 
    showHeader=true, 
    showTextOnButtons=true , 
    celled=false,
    maxHeight=null
}){

    const getLineCells = (is_record, index) => {

        let lt_cells = [];
        
        for (const ls_fcat of it_fcat) {

            // şuanki field button olarak mı görünmeli ?
       
            let ls_cells;

            let lv_button_field = false;     
            if (ls_fcat.fieldname.slice(0,6) === "button") 
            {
                lv_button_field = true;
            }

            if (lv_button_field)
            {

                // button ise 
                // ona çağrılan tarafta fieldcat üzerinde verilen function code
                // ve line index ile callback yapar :

                ls_cells = 
                    <Table.Cell width={ls_fcat.width}>  

                        <Button
                            content={ showTextOnButtons ? ls_fcat.title : null}      
                            onClick={() => onLineClick(ls_fcat.function_code, index)}                  
                            color={ls_fcat.button_color}
                            icon={ls_fcat.button_icon}
                            fluid={true}
                            size="mini"
                        />


                    </Table.Cell>;
            }
            else
            {

                // normal metin alanı düz ise yazdırılır :

                ls_cells = 
                    <Table.Cell width={ls_fcat.width}>  
                        {is_record[ls_fcat.fieldname]} 
                    </Table.Cell>;
            }


            lt_cells.push(ls_cells);
        }

        return lt_cells;
    }    

    // Table header satiri uret :

    let lt_header = [];

    it_fcat.map( ls_fcat => 

        {
            const ls_header = <Table.HeaderCell> {ls_fcat.title} </Table.HeaderCell>
            lt_header.push(ls_header)
        }
    )

    // Table hücrelerini üret :    

    let lt_lines = [];

    it_records.map( (ls_record, index) => 

        {
            const lt_cells = getLineCells(ls_record, index);

            const ls_line = 
            <Table.Row key={index}>
                {lt_cells}
            </Table.Row>

            lt_lines.push(ls_line);
        }
    )

    if (lt_lines.length > 0)
    {

    }
    else
    {
        return null;
    }

    let ls_maindiv_style = {};

    if (maxHeight)
        ls_maindiv_style = {maxHeight: maxHeight, overflow: "auto"}

return (

        <div style={ls_maindiv_style}>

        <Table 
            celled={celled}
            compact>

            {showHeader &&

                <Table.Header>
                    <Table.Row>
                        {lt_header}
                    </Table.Row>
                </Table.Header>
            }

            <Table.Body>
                {lt_lines}
            </Table.Body>
        </Table>

        </div>
)
}
export default BmTable;
