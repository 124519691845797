import React , { useRef
                // useLayoutEffect, 
                // useEffect 
            } 
from 'react';

import { Label, Segment } from 'semantic-ui-react';
import BmSeparator from '../BmSeparator';

function BmSegment({color="teal", maxHeight=null, children, headerText=null, headerSize="h3", visible=true}){

    const labelRef = useRef(null);

    // useEffect( () => {

    //     if (labelRef)
    //     {
    //         console.log('labelRef:', labelRef);

    //             // labelRef.current.props.style.setProperty("box-sizing", "unset", "important");
    //     }

    // },
    // []);

    

    // useLayoutEffect(() => {

    //     if (labelRef)
    //     {
    //         console.log('setting style:', labelRef.current.style);
    //         if (labelRef.current.style)
    //             labelRef.current.style.setProperty("box-sizing", "unset", "important");
    //     }
            
    //   }, []);        

    let ls_segment_style = {};
    if (maxHeight)
    {
        ls_segment_style.maxHeight = maxHeight;
        ls_segment_style.overflow = "auto"
    }
        

    if (!visible)
    {
        return (
            <>
                {children}
            </>
        )
    }



    return (

        <Segment color={color} raised style={ls_segment_style}>


            {/* <BmHeader title={headerText} visible={headerText} size={headerSize} inBlock={false} /> */}

            {headerText &&

                <>

                    <Label 
                        // attached='top left' 
                        // ribbon
                        // horizontal
                        // basic
                        color={color}
                        // style={{boxSizing: null}}
                        ref={labelRef}
                        id="top_label"
                    >
                        {headerText}
                    </Label>

                    <BmSeparator />          

                </>
            }

            {children}

        </Segment>    

)
}
export default BmSegment;
