import React from 'react';
import { Label } from 'semantic-ui-react';
import BmIcon from './BmIcon';

function BmLabel({text, icon, suLabel=false , suLabelColor="blue" }){

    let lv_textMarginLeft = "0px";
    
    if (icon)
        lv_textMarginLeft = "5px";

return (

    <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center"
    }}>

        {icon &&
            <BmIcon iconName={icon} />
        }

        <div style={{
            marginLeft: lv_textMarginLeft,
            lineHeight: "1.5",
            textAlign: "left",
            fontWeight: "bold"
            }}
        >

            {suLabel === true ?

                <Label color={suLabelColor}> 
                    {text} 
                </Label>
            :
                <> {text} </>
            }

        </div>

    </div>

    )
}
export default BmLabel;
