import React from 'react';
import { Formik } from 'formik';
import { Form } from "semantic-ui-react";
import BmHeader from '../BmHeaders/BmHeader';
import BmSegment from '../BmParentContainers/BmSegment';

function BmForm({initialValues, onSubmit, validationSchema, children, formikRef, formHeaderText, loading, inSegment=true}){
return (

    <Formik 
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
        innerRef={formikRef}
    >

        { () => (

            <BmSegment 
                visible={inSegment}
                color="blue">

                <Form loading={loading} autoComplete="on" size="small">    

                    {formHeaderText &&

                        <BmHeader 
                            title={formHeaderText}
                            inBlock={false}
                            size="h3"
                        />
                    }

                    {children}
                </Form>          

            </BmSegment>                            

        )}        

    </Formik>
)
}
export default BmForm;
