import React from 'react';
import BmButton from './BmButton';
import { withTranslation } from 'react-i18next';

function BmDeleteButton({t, onClick, color="red", icon="trash", text=null}){

    let lv_text  = t("BmLabels.close");
    if (text) 
    {
        lv_text = text;
    }
return (

    <BmButton
        text={lv_text}
        onClick={onClick}
        color={color}
        icon={icon}
    />

)
}

export default withTranslation()(BmDeleteButton);
