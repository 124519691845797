import React from 'react'
import { Route, Redirect } from 'react-router-dom';
import BmUserRoute from '../../BmLibrary/BmUserManagement/BmUserRoute';
import { connect } from 'react-redux';

import HomePage from '../Pages/HomePage/HomePage';

// import WorkTimePickerInput from '../../common_components/MultiUseForms/DateTimePicker/WorkTimePickerInput';


// import NewPatient from '../Patients/NewPatient_FirstForm';
import PatientEntryPage from '../Pages/Patients/PatientEntryPage';
import VisitEntryPage from '../Pages/Visits/VisitEntryPage';
import BmLogonPage from '../../BmLibrary/BmUserManagement/BmLogonPage';
import SitePage from '../Pages/SitePage/SitePage';
import OperEntryPage from '../Pages/Operations/OperEntryPage';
import GeneralRep from '../Pages/Reports/generalRep';
// import PatientSearchPage from '../Patients/PatientSearchPage';
 

class RouterForRole extends React.Component{
state = {}
render() {
return( 

    <div id="RouterMainDIV">

    {/* Kullanici login ise homepage,  degil ise logon ekranına gitsin : */}
    
    {this.props.user.token ?
        (
            <div>

                <BmUserRoute location={this.props.location} path="/Home" exact component={HomePage} />        

                <BmUserRoute 
                    location={this.props.location} 
                    path="/patientEntry" 
                    component={props => <PatientEntryPage {...props} />  }
                />        

                {/* <BmUserRoute 
                    location={this.props.location} 
                    path="/patientSearch" 
                    component={props => <PatientEntryPage {...props} asSearchForm={true}  />  }                    
                />         */}

                <BmUserRoute 
                    location={this.props.location} 
                    path="/visitEntry" 
                    component={props => <VisitEntryPage {...props} />  }                    
                />        

                <BmUserRoute 
                    location={this.props.location} 
                    path="/operEntry" 
                    component={props => <OperEntryPage {...props} /> }                    
                />        

                <BmUserRoute 
                    location={this.props.location} 
                    path="/genSearch" 
                    component={props => <GeneralRep {...props} /> }                    
                />        



                <Redirect to="/Home"/>            

            </div>
        )
        :
        (        
            <div>

                <Route path="/" exact component={SitePage} />                        
                <Route location={this.props.location} path="/logon" exact component={BmLogonPage} />                                                                             
                <Redirect to="/"/>    
            </div>
        )            
    }




    {/* <Route location={this.props.location} path="/test" exact component={TestComp} />             */}

    {/* <Route path="/confirm/:token" exact component={UserConfirmer} /> */}
    {/* <Route path="/ResetPwSender" exact component={ResetPwSender} />         */}
    {/* <Route path="/ResetPw/:token" exact component={ResetPw} /> */}

    {/* <Route path="/calendar" exact component={WorkTimePickerInput} /> */}

</div>


);
}
}

const mapStateToProps = (state) => {
return {
    user: state.user
    };
};

export default connect(mapStateToProps, null)(RouterForRole);

