import React from 'react';
import BmButton from './BmButton';
import { withTranslation } from 'react-i18next';

function BmSearchButton({t, onClick, color="blue", icon=null, text=null, fluid=true}){

    let lv_text  = t("BmLabels.search");
    if (text) 
        lv_text = text;

    let lv_icon = "search";

    if (icon)
        lv_icon = icon;
    
return (

    <BmButton
        text={lv_text}
        onClick={onClick}
        color={color}
        icon={lv_icon}
        fluid={fluid}
    />

)
}

export default withTranslation()(BmSearchButton);
