import React from 'react'
import { CSSTransition } from "react-transition-group";
 
class BmParentAnimator extends React.Component{

render() {

    let lv_anim_css = "FadeInCSS";
    // let lv_anim_css = "SlideFromRight";

    if (this.props.anim_css)
    {
        // SlideFromRight
        lv_anim_css = this.props.anim_css; 
    }

    return( 
        <CSSTransition
        in={true}
        timeout={2000}
        classNames={lv_anim_css}
        appear
        id="transition_div"
        style={{height: "100%"}}
        >        

            {this.props.children}

        </CSSTransition>
    );
}
}
export default BmParentAnimator;