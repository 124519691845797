
import { Message, Icon } from 'semantic-ui-react';

import React from 'react';
 
class BmLoadingMessage extends React.Component{
state = {}
 render() {
return( 
	<Message icon>
    
		<Icon name='circle notched' loading />    

		<Message.Content>

        {this.props.title &&
            <Message.Header>
                {this.props.title}
            </Message.Header>        
        }

        {this.props.mess &&
            <p> {this.props.mess} </p>
        }
      
		</Message.Content>
  
	</Message>

);
}
}
export default BmLoadingMessage;
