import React from 'react'
import BmHeader from '../../../BmLibrary/BmComponents/BmHeaders/BmHeader';
import BmIconHeader from '../../../BmLibrary/BmComponents/BmHeaders/BmIconHeader';
import BmParentAnimator from '../../../BmLibrary/BmComponents/BmParentContainers/BmParentAnimator';
import BmFlexCenteredColumn from '../../../BmLibrary/BmComponents/BmParentContainers/BmFlexCenteredColumn';

 
class SitePage extends React.Component{
state = {}
render() {

    
return( 

    <BmParentAnimator>

    <BmFlexCenteredColumn divHeight="90vh">

        <BmIconHeader 
                icon="doctor"
                headerText={"Dr. Damla Demir Kliniği"}
                // subText={this.state.timeValue.toLocaleString('tr-TR')} 
                size="h1"
            />

        <BmHeader title="Sitemiz Henüz Yapım Aşamasındadır"/>

    </BmFlexCenteredColumn>

    </BmParentAnimator>


);
}
}
export default SitePage;