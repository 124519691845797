// *** Bir form sayfası düşün
// *** koyunca direk kenarlara yapışmaması için padding ekler sadece

import React from 'react'
import { connect } from 'react-redux';
 
class BmParentSpacer extends React.Component{

render() {

    var ls_style_child = {};

    var lv_padding_horizontal = "20px";
    var lv_padding_vertical = "20px";

    if (this.props.padding)
    {
        lv_padding_horizontal = this.props.padding;
        lv_padding_vertical = this.props.padding;
    }
        
    if (this.props.onlyHorizontal)  // sadece yatay boşluk
        lv_padding_vertical = "0px"

    if (this.props.onlyVertical)
        lv_padding_horizontal = "0px"


    if (this.props.mobileView)
    {

        ls_style_child = {

            // "overflowX": "hidden",
            // "overflowY": "visible",            
            "padding" : "10px"
            // "height" : "80vh"                
        }    
    }
    else
    {

        ls_style_child = {

            // "overflowX": "hidden",
            // "overflowY": "visible",
            "paddingTop" : lv_padding_vertical,
            "paddingBottom" : lv_padding_vertical,

            "paddingLeft" : lv_padding_horizontal,
            "paddingRight" : lv_padding_horizontal
        }                

    }


return( 

    <>
        {this.props.passive ? 

            <>
                {this.props.children}
            </>

            :

            <div style={ls_style_child} id="bm_spacer">
                {this.props.children}
            </div>
        }

    </>


);
}
}

const mapStateToProps = (state) => {
return {
    mobileView: state.mobileView
    };
};

export default connect(mapStateToProps, null)(BmParentSpacer);