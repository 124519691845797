import React from 'react';
import { Message } from "semantic-ui-react";

function BmErrorMessage({message, subMessage, visible=true, icon="warning"}){

    if (!visible || !message) return null;       

    return (

        <Message 
            negative
            // error
            // icon={icon}
            header={message}
            content={subMessage} 
            size="mini"
            // compact={true}
        />
    )
}
export default BmErrorMessage;
