import React from 'react'
import BmSegment from '../../../BmLibrary/BmComponents/BmParentContainers/BmSegment';
import { withTranslation } from 'react-i18next';
import BmTable from '../../../BmLibrary/BmComponents/BmTable';
import BmButton from '../../../BmLibrary/BmComponents/BmButtons/BmButton';
import OperSelModal from './OperSelModal';
 
class OpersTable extends React.Component{

state = {
    showOperModal: false
}

lineClick = (ip_fcode, ip_index) => {    

    if (ip_fcode === "delete")
    {
        let lt_oper = [...this.props.t_oper];
        lt_oper.splice(ip_index, 1);
        this.props.setStateOper(lt_oper);
    }
}


addItem = (is_item)  => {

    let lt_oper = [...this.props.t_oper];
    lt_oper.push(is_item);
    this.props.setStateOper(lt_oper);

}

render() {
return( 

    <>

    <BmSegment 
        color="teal" 
        headerText={this.props.t("operations.opersTitle") +" :"}
        headerSize="h4"                
    >

        <BmTable
            it_records={this.props.t_oper}
            it_fcat={
                [
                    {
                        fieldname: "operText", 
                        title: this.props.t("operations.operText"),
                        width: 14
                    } ,

                    {
                        fieldname: "button_delete",    
                        title: this.props.t("BmLabels.delete"), 
                        function_code: "delete", 
                        button_icon: "trash alternate outline",
                        button_color: "red",
                        width: 2
                    }

                ]
            }

            onLineClick={(ip_fcode, ip_index) => this.lineClick(ip_fcode, ip_index)}                    
            showHeader={false}
            showTextOnButtons={false}                                     
        />

        <BmButton 
            text={this.props.t("operations.addOper")}
            onClick={ () => this.setState({showOperModal: true}) }
            color="teal"
            icon="plus"
            type="button"
        />                        

    </BmSegment>     

    <OperSelModal 
        visible={this.state.showOperModal}
        onCancel={ () => this.setState({showOperModal: false})}
        onClickItem={ 
            (item) => 
            {
                this.setState({showOperModal: false});
                this.addItem(item)
            }
        }
    />    
    
    </>


);
}
}
export default withTranslation()(OpersTable);