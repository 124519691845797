import React from 'react';
import { withTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Form, Label  } from "semantic-ui-react";

function BmFormPasswordField({t, fieldname, icon="lock", edit=true, ...otherProps}){

    const {setFieldTouched, touched, errors, values, handleChange} = useFormikContext();

    const lv_showError = edit && touched[fieldname] && errors[fieldname];

return (

    <>
        <Form.Field error={lv_showError}>    

        <label htmlFor={fieldname} style={{textAlign: 'left'}}>
            {t("BmLabels.password")}
        </label>                        

        <Form.Input
            fluid
            icon={icon}
            iconPosition='left'
            placeholder={t("BmLabels.password")}
            type='password'
            name={fieldname}
            value={values[fieldname]}
            // onChange={(text) => setFieldValue(fieldname, text)}
            onChange={handleChange(fieldname)}            
            onBlur={() => setFieldTouched(fieldname)}
            readOnly={!edit}
        />

        { lv_showError &&

            <Label basic color='red' pointing>
                {errors[fieldname]}
            </Label>        
        }

        </Form.Field>        

    </>

)
}

export default withTranslation()(BmFormPasswordField);
