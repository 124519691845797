import jwt_decoder from 'jwt-decode';

export const Redux_Set_User = (
    ip_token, 
    ip_redux_dispatch, 
    ip_history = null)  => 
{

    const ls_token_payload = jwt_decoder(ip_token);    

    let ls_user = {token: ip_token, ...ls_token_payload};

    localStorage.setItem('user', JSON.stringify(ls_user));

    ip_redux_dispatch({type: 'USER_LOGGED_IN', user: ls_user});      

      // show vertical menu for full screen : 
    ip_redux_dispatch(
      {type: 'FS_GRID_TOOGLE', 
      Bm_grid_settings: {MenuVisible: true, contentColWidth: 13}
    });

    if (ip_history)
        ip_history.push("/"); 

    return ls_user;
}