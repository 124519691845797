import React from 'react';
import { withTranslation } from 'react-i18next';
import BmParentCollapsibleLine from '../../BmParentContainers/BmParentCollapsibleLine';
import BmFormDropdownField from '../BmFormDropdownField';
import BmFormDropdownMonths from './BmFormDropdownMonths';
 
class BmFormBirthdaySelector extends React.Component{
state = {
    t_days: []
}

componentDidMount = () => {

    let lt_day_options = [];

    const ls_listbox_line = {
        key: "",
        text: "",
        value:""
    }

    lt_day_options.push(ls_listbox_line);

    // günlerin üretilmesi :
    for (let index = 0; index < 31; index++) {

        const lv_day_number = index + 1;
        const lv_day_string = lv_day_number.toString();

        if (lv_day_string.length === 1)
            lv_day_string = "0" + lv_day_string;

        const ls_listbox_line = {
            key: lv_day_string,
            text: lv_day_string,
            value:lv_day_string,
        }

        lt_day_options.push(ls_listbox_line);

    }

    this.setState({t_days: lt_day_options})

}

render() {


return( 


    <BmParentCollapsibleLine>

        <BmFormDropdownField 
            fieldname="birthDay"        
            placeholder={this.props.t("BmLabels.day")}            
            t_options={this.state.t_days}
            withoutTopLabel={true}
        />

        <BmFormDropdownMonths
            fieldname="birthMonth"
            placeholder={this.props.t("BmLabels.month")}
            withoutTopLabel={true}            
        />

    </BmParentCollapsibleLine>        

);
}
}


export default withTranslation()(BmFormBirthdaySelector);
