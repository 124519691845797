import bmAxiosClient from "../../GenAPI/axiosClient";

const saveVisit = (data) =>  bmAxiosClient.post('/api/visits/save   ', data);

const getPatientPastVisits = (patientId) => bmAxiosClient.get('/api/visits', {params: {patientId: patientId}});        

const readVisitById = (id) => bmAxiosClient.get('/api/visits/' + id);

const deleteVisit = (id) => bmAxiosClient.delete('/api/visits/delete', { data:{id: id}});

// const searchPatient = (data) => bmAxiosClient.get('/api/patients', {params: data});        



export default {saveVisit,
                getPatientPastVisits, 
                readVisitById,
                deleteVisit
                // deletePatient, 
                // searchPatient
            };
