import React from 'react';
import BmHeader from './BmHeaders/BmHeader';
import { connect } from 'react-redux';
import BmLabel from './BmLabel';
 
class BmMenuItem extends React.Component{


render() {

    if (this.props.mobileView)
    {
        return (

            <BmLabel
                text={this.props.text}
                icon={this.props.icon}
            />
        )
    }
    else
    {
        return (
        
            <BmHeader 
                title={this.props.text}
                icon={this.props.icon}
                size="h5" 
                inBlock={false} 
                padding="0px" 
            />
        )
    }

}
}

const mapStateToProps = (state) => {
    return {
        mobileView: state.mobileView
        };
    };

export default connect(mapStateToProps)(BmMenuItem);
    
