import React from 'react'
import { Header } from "semantic-ui-react";

function BmHeader({title, subtitle, centered=true, size="h2", padding="10px", icon=null, inBlock=true, visible=true,...props}){

    if (!visible) return null;

    var ls_style_container = {
            padding: padding
        }    

    if (centered === true)
    {
        ls_style_container.textAlign = "center"
    }

return (

        <div style={ls_style_container}> 


            <Header 
                as={size}
                content={title}
                subheader={subtitle} 
                block={inBlock}
                icon={icon}
                {...props}
            />            

        </div>

    )
}
export default BmHeader;