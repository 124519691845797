// alttaki şekilde N adet child verdin, 
// bunları yan yana grid içinde açar ... ekran daralınca da alt alta collapse eder :

// <BmParentCollapsibleLine>
//    <Input1> <Input2> <Input3>
// </BmParentCollapsibleLine>

import React from 'react';
import { Grid } from 'semantic-ui-react';

function BmParentCollapsibleLine({children, marginTop=3, marginBottom=3}){

    let lt_columns = [];
    const lv_child_count = children.length;

    for (const ls_curr_child of children) {

        const ls_column = 
        <Grid.Column stretched> 

                {ls_curr_child}

        </Grid.Column>;
    
        lt_columns.push(ls_column);
    
    }

return (

    <div style={{marginTop:marginTop, marginBottom: marginBottom}}>

        <Grid 
            columns={lv_child_count}
            stackable
        >
            <Grid.Row>

                {lt_columns}

            </Grid.Row>

        </Grid>

    </div>

)
}
export default BmParentCollapsibleLine;
