import React from 'react';
import { Header, Icon } from 'semantic-ui-react';

function BmIconHeader({icon, headerText, subText, size="h2", iconColor="black"}){
return (

    <div style={{ textAlign: "center"}}>

        <Header as={size} icon>

            <Icon name={icon} color={iconColor}/>

            {headerText}

            {subText &&
                <Header.Subheader>
                    {subText}
                </Header.Subheader>
            }

        </Header>        

    </div>

)
}
export default BmIconHeader;
