import { Menu } from "semantic-ui-react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react'
import { withTranslation } from 'react-i18next';
import BmMenuItem from "../../BmLibrary/BmComponents/BmMenuItem";
import BmSeparator from "../../BmLibrary/BmComponents/BmSeparator";
 
class VertMenuItems extends React.Component{
state = {}


onHomeClick = (ip_item_no, ip_page) => {

}


onClick = (event, data) =>{

    this.props.redux_dispatch({type:'SET_ACTIVE_MENU_ITEM', activeMenuItem: data.name});   

    this.props.redux_dispatch({type: 'SET_MOB_SIDEBAR', mobileSidebarOpen: false});

    this.props.history.push(data.name);

}

render() {

    if (!this.props.user.token)
    {

        return(

            <div>
    
            <Menu.Item 
                    name='/logon' 
                    active={this.props.activeMenuItem === '/logon'}
                    onClick={this.onClick}
                > 
                {this.props.t("BmLabels.logon")}
            </Menu.Item>           
    
            </div>
    

        )

    }
    else
    {
        return( 

            <div>

                <Menu.Item 
                    name='/Home' 
                    active={this.props.location.pathname === '/Home'}
                    onClick={this.onClick}
                > 

                    <BmMenuItem 
                        text={this.props.t("generalLabels.mainPage")}
                        icon="home"
                    />

                </Menu.Item>                                                

                <Menu.Item 
                    name='/genSearch' 
                    active={this.props.location.pathname === '/genSearch'}
                    onClick={this.onClick}
                > 
                    
                    <BmMenuItem 
                        text={this.props.t("generalLabels.genSearch")}
                        icon="search"
                    />

                </Menu.Item> 



                <Menu.Item 
                    name='/patientEntry' 
                    active={this.props.location.pathname === '/patientEntry'}
                    onClick={this.onClick}
                > 
                    
                    <BmMenuItem 
                        text={this.props.t("patientMaster.newPatientTitle")}
                        icon="sticky note outline"
                    />

                </Menu.Item>

                <Menu.Item 
                    name='/visitEntry' 
                    active={this.props.location.pathname === '/visitEntry'}
                    onClick={this.onClick}
                > 
                    
                    <BmMenuItem 
                        text={this.props.t("visits.visitEntyTitle")}
                        icon="calendar alternate outline"
                    />

                </Menu.Item>

                <BmSeparator />


                <Menu.Item 
                    name='/operEntry' 
                    active={this.props.location.pathname === '/operEntry'}
                    onClick={this.onClick}
                > 
                    
                    <BmMenuItem 
                        text={this.props.t("operations.operEntryTitle")}
                        icon="list alternate"
                    />

                </Menu.Item>                

               





                {/* <Menu.Item 
                    name='/Sbmg' 
                    active={this.props.activeMenuItem === '/Sbmg'}
                    onClick={this.onClick}
                > 
                    {this.props.t("sbmg.title")}
                </Menu.Item>

                <Menu.Item 
                    name='/Sb' 
                    active={this.props.activeMenuItem === '/Sb'}
                    onClick={this.onClick}
                > 
                    {this.props.t("sb.title")}
                </Menu.Item>

                <Menu.Item 
                    name='/citymaint' 
                    active={this.props.activeMenuItem === '/citymaint'}
                    onClick={this.onClick}
                > 
                    {this.props.t("adress.city_admin_title")}
                </Menu.Item>

                <Menu.Item 
                    name='/districtmaint' 
                    active={this.props.activeMenuItem === '/districtmaint'}
                    onClick={this.onClick}
                > 
                    {this.props.t("adress.district_admin_title")}
                </Menu.Item> */}

            </div>

            );
    }


}
}

const mapStateToProps = (state) => {
return {
    mobileSidebarOpen: state.mobileSidebarOpen,
    activeMenuItem: state.activeMenuItem,
    user: state.user

    };
};
const mapDispatchToProps = (dispatch) => {
return {
    redux_dispatch : (ip_action) => dispatch(ip_action)
        };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(VertMenuItems)));
