import React from 'react';
import BmCloseButton from '../BmButtons/BmCloseButton';
import BmErrorMessage from '../BmMessages/BmErrorMessage';
import BmModal from './BmModal';

function BmErrorModal({visible, text, onClose}){
return (

    <BmModal visible={visible}>

        <BmErrorMessage message={text} icon="close" />

        <BmCloseButton onClick={ () => onClose()} />

    </BmModal>

)
}
export default BmErrorModal;
