import React from 'react';
import { useFormikContext } from 'formik';
import { Form, Label, TextArea  } from "semantic-ui-react";

function BmFormLongDescField({fieldname, icon="info", edit=true, ...otherProps}){

    const {setFieldTouched, touched, errors, values, handleChange} = useFormikContext();

    const lv_showError = edit && touched[fieldname] && errors[fieldname];

return (

    <>
        <Form.Field error={lv_showError}>    

        <label htmlFor={fieldname} style={{textAlign: 'left'}}>
            {otherProps.placeholder}
        </label>                        

    

        <TextArea
            placeholder={otherProps.placeholder} 
            value={values[fieldname]}
            onChange={handleChange(fieldname)}      
            onBlur={() => setFieldTouched(fieldname)}
            // style={ls_style_text}       
            error={lv_showError}       
            readOnly={!edit}
        />                

        {/* <Form.Input
            fluid
            icon={icon}
            iconPosition='left'
            placeholder={otherProps.placeholder}
            type='text'
            name={fieldname}
            --value={values[fieldname]}
            --onChange={handleChange(fieldname)}            
            onBlur={() => setFieldTouched(fieldname)}
            readOnly={!edit}
        /> */}

        { lv_showError &&

            <Label basic color='red' pointing>
                {errors[fieldname]}
            </Label>        
        }

        </Form.Field>        

    </>

)
}
export default BmFormLongDescField;
