import React from 'react';
import BmButton from './BmButton';
import { withTranslation } from 'react-i18next';

function BmCloseButton({t, onClick, color="black", icon="close", fluid=true}){
return (

    <BmButton
        text={t("BmLabels.close")}
        onClick={onClick}
        color={color}
        icon={icon}
        fluid={fluid}
    />

)
}

export default withTranslation()(BmCloseButton);
