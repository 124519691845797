import React from 'react';
import { withTranslation } from 'react-i18next';
import BmCloseButton from '../BmButtons/BmCloseButton';
import BmSaveButton from '../BmButtons/BmSaveButton';
import BmHeader from '../BmHeaders/BmHeader';
import BmSeparator from '../BmSeparator';
import BmModal from './BmModal';

function BmConfirm({t,visible, text, onCancel, onConfirm, headerText=null}){

let lv_header = null;

if (headerText) lv_header = headerText
else lv_header = t("BmLabels.confirmTitle") + ":";

return (

    <BmModal 
        visible={visible}
    >
        <BmHeader
            title={lv_header}
            centered={false}
            size="h3"
            inBlock={false}
        />

        <BmSeparator />

        {text}

        <BmSeparator />

        <div style={{display: "flex", 
                     flexDirection: "row",
                     justifyContent:"flex-end", 
                     alignItems: "center"}}>


            <BmCloseButton
                text={t("BmLabels.cancel")}
                onClick={onCancel}
                fluid={false}
            />

            <BmSaveButton
                text={t("BmLabels.continue")}
                onClick={onConfirm}
                fluid={false}                
            />

    </div>

    </BmModal>

)
}

export default withTranslation()(BmConfirm);
