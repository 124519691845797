import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter, Route }  from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';

import './BmLibrary/BmStyles/gen.css';
import './BmLibrary/BmStyles/Transitions/SlidingForms.css';
import './BmLibrary/BmStyles/Transitions/FadeIn.css'

// import './CSS/gen.css';
// import './CSS/Transitions/SlidingForms.css';
// import './CSS/Transitions/FadeIn.css'

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

import { Provider } from 'react-redux';
import configureStore from './ConfigureStore';
import BmErrorBoundry from './BmLibrary/BmExceptionHandling/BmErrorBoundry';

const store = configureStore();

var ls_user = JSON.parse(localStorage.getItem('user'));

if (ls_user)
{
    store.dispatch({type:'USER_LOGGED_IN', user: ls_user});       
}


ReactDOM.render(
    <BrowserRouter>                
        <I18nextProvider i18n={i18n}>
            <Provider store={store}>        
                <BmErrorBoundry>
                    <Route component={App} />  
                </BmErrorBoundry>    
            </Provider>
        </I18nextProvider>
    </BrowserRouter>, 

    document.getElementById('root')

    );


    
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
