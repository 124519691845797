import React from 'react'
import BmHeader from '../BmComponents/BmHeaders/BmHeader';
import { withTranslation } from 'react-i18next';
import BmFlexCenteredColumn from '../BmComponents/BmParentContainers/BmFlexCenteredColumn';

class BmExceptionScreen extends React.Component{

render() {
return( 
    <BmFlexCenteredColumn divHeight="80vh">

        <BmHeader icon="cogs" title={this.props.t("BmLabels.errorOccured")} />

    </BmFlexCenteredColumn>
);
}
}


export default withTranslation()(BmExceptionScreen);