// Uygulama için apiUrl tektir
// Uygulama bazında alttaki metni değiştirmek üzere, 
// BmLibrary içerisine aldım

const settings = {

    dev: {
        apiUrl: 'http://192.168.0.29:5000/api',
        timeout: 5000
    },

    prod: {
        apiUrl: 'http://www.kliniksis.com/api',
        timeout: 10000
    }
}

const getSettings = () => {

    if (process.env.NODE_ENV === 'development') return settings.dev
    else return settings.prod;

}

export default getSettings;