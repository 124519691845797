import React from "react";
import { useFormikContext } from "formik";
import BmSaveButton from "../BmButtons/BmSaveButton";
import BmSearchButton from "../BmButtons/BmSearchButton";

function BmFormSubmitButton({ asSearchButton=false, text=null, icon=null }) {

  const { handleSubmit } = useFormikContext();

  return (
  
    <>

      { asSearchButton ?

        <BmSearchButton 
          text={text}
          icon={icon}          
          onClick={(data) => handleSubmit()} 
          type="submit"          
        />  // ara

        :

        <BmSaveButton 
          text={text}
          icon={icon}
          onClick={(data) => handleSubmit()} 
          type="submit"
        />   // kaydet

      }

    </>

  )
  
}

export default BmFormSubmitButton;