// *** Bir logon formu ve 2 input hayal et
// *** bunun tum sayfaya yayilmadan,  sayfanin tam ortasina gorunmesini saglar
// *** giriş sayfası , register sayfası gibi boşluk çok ama ortlama istiyorsan işe yarar

import React from 'react'
import { connect } from 'react-redux';
 
class BmFlexCenteredColumn extends React.Component{

render() {

    var ls_styleFlex =
    {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    }    

    if (this.props.divHeight)    
        ls_styleFlex.height = this.props.divHeight
    else
        ls_styleFlex.flex = "1";

return( 


    <div
        id={this.props.id} 
        style={ls_styleFlex}>        

        {this.props.children}

    </div>



);
}
}

const mapStateToProps = (state) => {
return {
    mobileView: state.mobileView
    };
};

export default connect(mapStateToProps, null)(BmFlexCenteredColumn);