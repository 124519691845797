import { mobileWidth, mobileView, mobileSidebarOpen, currWidth } from './BmLibrary/BmDashboard/BmMobSizeReducer';
// import { activeMenuItem } from './components/pages/dashboard/sidebar/ActiveItemReducer';
import { user } from './BmLibrary/BmUserManagement/BmUserReducer';
import { Bm_grid_settings } from './BmLibrary/BmDashboard/BmDashboardGridReducer';
import { combineReducers } from 'redux';

export default combineReducers({

    Bm_grid_settings,    
    mobileWidth,
    mobileView,
    mobileSidebarOpen,
    currWidth,
    user

});