import React from 'react';
import BmDashboard from './BmLibrary/BmDashboard/BmDashboard';
import { withTranslation } from 'react-i18next';
import VertMenuItems from './AppOwn/RouteItems/VertMenuItems';
import RoutesForRole from './AppOwn/RouteItems/RoutesForRole';
import { connect } from 'react-redux';

class App extends React.Component{
state = {}

render() {

   return( 

      <>
            
      {this.props.user ?

         (
            <BmDashboard location={this.props.location}>    
               <VertMenuItems />
               <RoutesForRole />
            </BmDashboard>
         )
         :
         (
            <>
               <RoutesForRole />      
            </>
         )

      }
      
      
      </>

   )   


}
}

// export default App;

// const App = ({location, user, isAuthenticated}) => ( 

//    {user ?

//       (
//       <BmDashboard location={location}>    
//          <VertMenuItems />
//          <RoutesForRole />
//       </BmDashboard>
//       )
//       :
//       )
//       <>
//          <RoutesForRole />      
//       </>
//       )

//    }


// );

const mapStateToProps = (state) => {
   return {
       user: state.user
       };
   };
   
export default connect(mapStateToProps)(withTranslation()(App));
   
   

// export default withTranslation()(App);



