import React from "react";

import { useFormikContext } from 'formik';
import { Form, Label } from "semantic-ui-react";


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";

import tr from 'date-fns/locale/tr';
import BmIcon from "../../BmIcon";
import { toOnlyDate_UTC } from "../../../BmFunctions/BmDateFunctions";
registerLocale('tr', tr)

function BmFormDateField({fieldname, edit=true, monthSelection=false, ...otherProps}){

    const {setFieldTouched, 
           touched, 
           errors, 
           values, 
           setFieldValue
        } = useFormikContext();    

    const lv_showError = edit && touched[fieldname] && errors[fieldname];    

    let lv_dateFormat = "dd.MM.yyyy";

    let lv_showMonthYearPicker = false;
    let lv_showFullMonthYearPicker = false;

    if (monthSelection)
    {
        lv_dateFormat = "MM.yyyy";
        lv_showMonthYearPicker = true;
        lv_showFullMonthYearPicker = true;
    }


return (

    <>

        <Form.Field error={lv_showError}>    

            <label htmlFor={fieldname} style={{textAlign: 'left'}}>
                {otherProps.placeholder}
            </label>             

            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            }}>

                <BmIcon iconName="calendar alternate outline" disabled={true}/>                    
                
                <DatePicker 
                    // selected={startDate} 
                    selected={values[fieldname]}
                    // onChange={(date) => setStartDate(date)} 
                    onChange={date => {

                            if (date) {

                                // gelen tarihin sadece gün kısmını alıp UTC olarak çevir ve setle 
                                // (saat ve zaman dilimi ile ilgilenmeden sadece o anki tarihi aldım,
                                //  ve UTC olarak kabul edip JS'in zaman dilimine göre tarih ile oynamasından kurtuldum) :

                                var lv_utcDate = toOnlyDate_UTC({ip_date:date});
                                setFieldValue(fieldname, lv_utcDate)

                            }
                            else
                            {
                                setFieldValue(fieldname, date)
                            }
                        }
                    } 

                    onBlur={() => setFieldTouched(fieldname)}

                    dateFormat={lv_dateFormat}
                    showMonthYearPicker={lv_showMonthYearPicker}
                    showFullMonthYearPicker={lv_showMonthYearPicker}

                    locale={tr}
                    // customInput={<CustomInput />}
                    readOnly={!edit}            
                />  

            </div>     

            { lv_showError &&

            <Label basic color='red' pointing>
                {errors[fieldname]}
            </Label>        
            }

        </Form.Field>        

</>


)
}
export default BmFormDateField;
